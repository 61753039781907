<table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <div mat-dialog-content style="overflow-x: hidden;">
    <div fxFlex="100%">

      <div style="margin-top: 1%;" fxLayout="row wrap" fxLayoutGap="20px">
        <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%" >

          <ng-container fxFlex matColumnDef="personaId">
            <th mat-header-cell *matHeaderCellDef> Socio Pasiolo </th>
            <td mat-cell *matCellDef="let element"> {{element.socioPacciolo}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="numeroAutorizacion">
            <th mat-header-cell *matHeaderCellDef> NumeroAutorizacion </th>
            <td mat-cell *matCellDef="let element"> {{element.numeroAutorizacion}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="prefijo" >
            <th mat-header-cell *matHeaderCellDef> Prefijo </th>
            <td mat-cell *matCellDef="let element"> {{element.prefijo}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="fechaInicio">
            <th mat-header-cell *matHeaderCellDef> FechaInicio </th>
            <td mat-cell *matCellDef="let element"> {{element.fechaInicio}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="fechaFin">
            <th mat-header-cell *matHeaderCellDef> FechaFin </th>
            <td mat-cell *matCellDef="let element"> {{element.fechaFin}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="tipoResolucion">
            <th mat-header-cell *matHeaderCellDef> TipoResolucion </th>
            <td mat-cell *matCellDef="let element"> {{element.tipoResolucion}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="numeroInicial">
            <th mat-header-cell *matHeaderCellDef> NumeroInicial </th>
            <td mat-cell *matCellDef="let element"> {{element.numeroInicial}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="numeroFinal">
            <th mat-header-cell *matHeaderCellDef> NumeroFinal </th>
            <td mat-cell *matCellDef="let element"> {{element.numeroFinal}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </div>
      </div>
    </div>
  </div>
</table>