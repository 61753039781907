import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { PaymentMovii } from '@app/shared/components/types/paymentMovi.interface';
import { ConsToken } from '@app/_services/constToken';
import { HttpClientService } from '@app/_services/http_client_service/http-client.service';

import { Respuesta } from '@app/_interfaces/respuesta';

import { FacturaType } from '../types/factura.types';
import { NotaFacturaType } from '../types/notaFactura.type';

@Injectable({
  providedIn: 'root'
})
export class FacturacionService {

  private listInvoiceLocal$ = new Subject<FacturaType[]>();

  constructor(private http: HttpClientService) { }

  getFacturasAGenerar(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/factura/generateBulkBilling', data);
  }

  guardarUniqueFactura(data: any): Observable<Respuesta> {
    return this.http.post(`${ConsToken.urlNode}/facturacion`, data);
  }

  getPeridoOfModal(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getPeriodoOfModal/${socioPacciolo}`)
  }

  getInvoiceDetail(invoiceId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getInvoiceDetail/${invoiceId}`);
  }

  guardarFacturaBlock(data: any): Observable<Respuesta> {
    return this.http.post(`${ConsToken.urlNode}/facturacion/facturarBloque`, data);
  }

  saveBlockInvoices(invoicesList: FacturaType[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/factura/saveBlockInvoices', invoicesList);
  }

  filterInvoices(data: any, socioPacciolo: number): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + `/factura/filterInvoices/${socioPacciolo}`, data);
  }

  /**
   * @description
   * Función que busca las facturas de cliente o sucursal
   * @param direccionId Contiene la el ID de una dirección tipo 1 0 2
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  getInvoicesClient(direccionId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getInvoicesClient/${direccionId}/${socioPacciolo}`);
  }

  getCurrentBalancePaymentsUnpaidInvoicesClient(id: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/common/getCurrentBalancePaymentsUnpaidInvoicesClient/${id}/${socioPacciolo}`);
  }

  saveInvoiceSupplier(data: FacturaType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/factura/saveInvoiceSupplier', data);
  }

  /**
   * @description Función que busca las facturas por saldo pendiente de un proveedor
   * @param tercero Contiene el ID de un tercero
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  getInvoicesToPaySupplier(tercero: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getInvoicesToPaySupplier/${tercero}/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que busca las facturas por pagar de un tercero
   * @param direccionId Contiene el ID de una dirección
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  getInvoicesToPay(direccionId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getInvoicesToPay/${direccionId}/${socioPacciolo}`);
  }

  moviiSale(data: PaymentMovii): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/movii/sale', data);
  }

  /**
   * @description
   * Función que busca las facturas facturas pasadas en base a un intervalo de meses
   * @param tercero Contiene el ID de un tercero
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   * @param interval Contiene el numero de meses hacia atras que queremos evaluar
   */
  getInvoicesByLastMonth(tercero: number, socioPacciolo: number, interval: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura +
      `/factura/getInvoicesByLastMonth/${tercero}/${socioPacciolo}/${interval}`);
  }

  /**
   * @description
   * Función que obtiene la información de las facturas para generar
   * el excel de Exógena
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   * @param year Contiene un number
   * @returns Un Array de tipo any
   */
  getInvoicesExogenous(socioPacciolo: number, year: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getInvoicesExogenous/${socioPacciolo}/${year}`);
  }

  /**
   * @description
   * Función que obtiene la información de una factura
   * @param facturaId Contiene el ID de una factura
   */
  getOneInvoice(facturaId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getOneInvoice/${facturaId}`);
  }

  getInvoice(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getInvoicess`);
  }

 

  /**
   * @description
   * Función que guarda una nota crédito o débito
   * @param note Contiene la información de la nota
   */
  saveNoteInvoice(note: NotaFacturaType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/factura/saveNoteInvoice', note);
  }

  /**
   * @description
   * Función que elimina una nota asociada a una factura
   * @param noteId Contiene el ID de una nota
   */
  deleteNote(noteId: number): Observable<Respuesta> {
    return this.http.delete(ConsToken.urlMicroServicePagosFactura + `/factura/deleteNote/${noteId}`);
  }

  /**
   * @description
   * Función que ejecuta una nota
   * @param noteId Contiene el ID de una nota
   * @param socioPacciolo Contiene el ID De un socioPaccicolo
   */
  executeNote(noteId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceFacturaElectronica + `/uploadNote/${noteId}/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que obtiene la ultima factura de una dirección
   * @param direccionId Contiene el ID de una dirección
   */
  getLastInvoiceByAddress(direccionId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getLastInvoiceByAddress/${direccionId}`);
  }

  /**
   * @description
   * Función que obtiene la ultima factura por el ID del contrato
   * @param contratoId Contiene el ID de un contrato
   */
  getLastInvoiceByContract(contratoId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getLastInvoiceByContract/${contratoId}`);
  }

  /**
   * @description
   * Función que guarda una factura
   * @param invoice Contiene un objeto de tipo FacturasInterface
   */
  saveOnInvoice(invoice: FacturaType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/factura/saveOnInvoice', invoice);
  }

  /**
   * @description
   * Función que obtiene el listado de facturas de un proveedor
   * @param direccionId Contiene un number
   * @param socioPacciolo Contiene el ID de un
   * @returns un array de tipo FacturasInterface
   */
  getInvoicesSupplier(direccionId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/factura/getInvoicesSupplier/${direccionId}/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que elimina la factura de un proveedor
   * @param facturaId Contiene un number
   */
  deleteInvoiceSupplier(facturaId: number): Observable<Respuesta> {
    return this.http.delete(ConsToken.urlMicroServicePagosFactura + `/factura/deleteInvoiceSupplier/${facturaId}`);
  }
}
