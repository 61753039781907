import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser/';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-modal-view-pdf',
  templateUrl: './modal-view-pdf.component.html',
  styleUrls: ['./modal-view-pdf.component.scss']
})
export class ModalViewPdfComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _DomSanitizationService: DomSanitizer
  ) { }

  ngOnInit(): void {
  }
}
