import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { DireccionType } from '@app/persona/types/direccion.type';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-list-solo-nombre',
  templateUrl: './list-solo-nombre.component.html',
  styleUrls: ['./list-solo-nombre.component.scss']
})
export class ListSoloNombreComponent implements OnChanges {

  @Input() direccionList: DireccionType[];
  @Input() displayedColumns: String[]

  @ViewChild('paginador') paginador: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<DireccionType>();
  constructor() { }

  copyText(textToCopy: string): void {
    const input = document.createElement('input');
    input.value = textToCopy;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.changeDireccionList(changes)
    console.log(this.direccionList, 'direciconList')
  }

  private changeDireccionList(change: SimpleChanges): void {
    if (change.direccionList && !change.direccionList.firstChange) {
      this.direccionList = change.direccionList.currentValue;
      this.dataSource.data = change.direccionList.currentValue;
      setTimeout(() => {
        this.dataSource.paginator = this.paginador;
        this.dataSource.sort = this.sort;
      }, 300);
    }
  }



}
