import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { CiudadType } from '@app/persona/types/ciudad.type';
import { DireccionType } from '@app/persona/types/direccion.type';
import { ADDRESSTYPES } from '@app/persona/util/constantes';

@Pipe({
  name: 'cedula',
  pure: false
})
export class CedulaPipe implements PipeTransform {
  transform(ced: string): string {

    if (ced) {
      let aux = 1;
      let ret = '';
      // // console.log("length del documento", ced.length);
      for (let i = ced.length + 1; i >= 0; i--) {
        // // console.log("valor de i", i);
        // // console.log("valor del char", ced.charAt(i));
        if (ced.charAt(i) + '' !== '') {
          if (aux === 3 && i > 0) {
            ret = '.' + ced.charAt(i) + ret;
            aux = 1;
          } else {
            ret = ced.charAt(i) + ret;
            aux++;
          }
        }
        // // console.log("ret en pipe", ret);
      }

      ced = ret;
      return ced;
    }

    return null;
  }
}

@Pipe({
  name: 'callback',
  pure: false
})
export class ProvidersPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }
}

@Pipe({
  name: 'filterProdName',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.nombre.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'filterTercero',
  pure: false
})
export class FilterSucursal implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.tercero.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'filterProdCod',
  pure: false
})
export class FilterCode implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.codigo.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'filterProdTipe',
  pure: false
})
export class FilterTP implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.nombre_tipo_producto.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'filterProdGroup',
  pure: false
})
export class FilterGroup implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.nombre_grupo.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  transform(items: any[], sortedBy: string): any {
    return items.sort((a, b) => {
      return b[sortedBy] - a[sortedBy];
    });
  }
}

@Pipe({
  name: 'filterDiaDescanso',
  pure: false
})

export class FilterDiaDescanso implements PipeTransform {
  transform(items: any[], term: any): any {
    return term ? items.filter(item => item.estado === term) : items;
  }
}

@Pipe({
  name: 'filterSelectListarProducto',
  pure: false
})
export class FilterSelectLP implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.tipo_producto.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'filterCodListarProducto',
  pure: false
})
export class FilterCodLP implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.codigo.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'filterNameListarProducto',
  pure: false
})
export class FilterNameLP implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.nombre.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'caracteristicas',
  pure: false
})
export class CaracteristicasLP implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }
}

@Pipe({
  name: 'idPersona',
  pure: false
})
export class FilterIdPersona implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.NumeroIdentificacion.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'namePersona',
  pure: false
})
export class FilterNamePersona implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.Nombre.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'tipoPersona',
  pure: false
})
export class FilterTipoPersona implements PipeTransform {
  transform(items: any[], term: any): any {
    return term
      ? items.filter(item => item.TipoPersona.indexOf(term) !== -1)
      : items;
  }
}

@Pipe({
  name: 'filterFecha',
  pure: false
})
export class FilterFecha implements PipeTransform {
  transform(items: any[], term: any): any {
    return term ? items.filter(item => item.fecha === term) : items;
  }
}

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
  name: 'mister'
})
export class Mister implements PipeTransform {

  transform(items: any[], term: any): any {
    // console.log(term);
    return term ? items.filter(item => item.Nombre === term) : items;
  }
}

@Pipe({
  name: 'formatAddress'
})
export class FormatAddress implements PipeTransform {
  transform(address: DireccionType, ciudades: CiudadType[]): string {
    const city = ciudades.find((ciu: CiudadType) => ciu.id === address.ciudadId);
    const barrio = address.barrioId ? 'Barrio ' + address.barrio.nombre + ',' : '';
    const conjuntoEdificioCc = address.conjuntoEdificioCc ? ` ${address.conjuntoEdificioCc}` : '';
    const direccion = `${barrio} ${address.descripcionGeneral}${conjuntoEdificioCc}, ${city.nombre}`;

    return direccion.trim();
  }
}

@Pipe({
  name: 'getAddressType'
})
export class GetAddressType implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return ADDRESSTYPES.find((item: any) => item.value === value).name;
    }
    return null;
  }
}
@Pipe({
  name: 'wasteAbbreviation'
})
export class WasteAbbreviation implements PipeTransform {
  transform(value: string): string {

    switch (value) {

      case 'sucursales': {
        return 'SUCURSALES';
      }

      case 'anatomopatologico': {
        return 'A';
      }

      case 'biosanitarios': {
        return 'B';
      }

      case 'cortopunzantes': {
        return 'C';
      }

      case 'farmacos': {
        return 'F';
      }

      case 'mercuriales': {
        return 'M';
      }

      case 'metales': {
        return 'MT';
      }

      case 'reactivos': {
        return 'R';
      }

      case 'otros': {
        return 'O';
      }

      case 'aceites': {
        return 'AC';
      }

      case 'raes': {
        return 'RA';
      }

      case 'mezclados': {
        return 'MZ';
      }

      case 'numeroCanastasEntregadas': {
        return '#CA';
      }

      case 'total': {
        return 'Total';
      }

      case 'pdf': {
        return 'pdf';
      }

      default:
        return 'Cédula';
    }
  }
}

@Pipe({
  name: 'totalResidues'
})
export class TotalResidues implements PipeTransform {
  transform(residue: any): number {
    if (residue) {
      return residue.aceites + residue.anatomopatologico + residue.biosanitarios +
        residue.cortopunzantes + residue.farmacos + residue.mercuriales + residue.metales
        + residue.mezclados + residue.otros + residue.raes + residue.reactivos;
    }
    return 0;
  }
}

@Pipe({
  name: 'calculatePercentage'
})
export class CalculatePercentage implements PipeTransform {
  transform(value: number, total: number): string {
    if (value > 0) {

      const percentage = ((value / total) * 100);

      /**
       * Se valida si el valor en la constante percentage es entero o decimal
       * si entra en la condición quiere decir que el valor de percentage es entero
       */
      if ((percentage - Math.floor(percentage)) === 0) {
        return `${percentage}%`;
      }

      return `${percentage.toFixed(2)}%`;
    }

    return '0%';
  }
}

@Pipe({
  name: 'sumAllValueOfArray'
})
export class SumAllValueOfArray implements PipeTransform {
  transform(paymentList: any[], property: string): number {
    return paymentList.reduce((acc: number, cur: any) => acc + cur[property], 0);
  }
}
