import { Injectable } from '@angular/core';

@Injectable()
export class ConsToken {
  // TODO Estas url no se estan usando(Borrar endpoints que lo estan usando)
  static url = 'http://localhost/backend';
  static urlNode = 'http://localhost:1105';
  // TODOEND

  static urlEntryPoint = 'https://api.pasiolo.com/auth';
  static urlMicroServicePersonas = 'https://api.pasiolo.com/personas';
  static urlMicroServicePagosFactura = 'https://api.pasiolo.com/pagos';
  static urlMicroServiceInventario = 'https://api.pasiolo.com/inventario';
  static urlMicroServiceContratos = 'https://api.pasiolo.com/contratos';
  static urlMicroServiceRedAmbiental = 'https://api.pasiolo.com/redambiental';
  // static urlMicroServiceRedAmbiental = 'http://localhost:4008';
  static urlMicroServiceGestionContable = 'http://35.202.87.49:4010';
  static urlMicroServiceFacturaElectronica = 'https://api.pasiolo.com/fe/soap';


  // static urlEntryPoint = 'http://181.48.223.134:4003';
  // static urlMicroServicePersonas = 'http://181.48.223.134:4005';
  // static urlMicroServicePagosFactura = 'http://181.48.223.134:4004';
  // static urlMicroServiceInventario = 'http://181.48.223.134:4006';
  // static urlMicroServiceContratos = 'http://181.48.223.134:4007';
  // static urlMicroServiceRedAmbiental = 'http://181.48.223.134:4008';
  // static urlMicroServiceGestionContable = 'http://35.202.87.49:4010';
  // static urlMicroServiceFacturaElectronica = 'http://181.48.223.134:4011/soap';


  static mask: {
    cop: {
      prefix: '$COP';
      mask: '0000.00';
    };
  };

  /**
   * Funcion para setear fecha de ng-bootstrap, implementado en lista de producto caracteristica
   */
  // tslint:disable:radix
  static setFecha(fechaJson: any): any {
    if (fechaJson.length !== 'undefined') {
      const fechaString = JSON.stringify(fechaJson);
      const fechaResult = fechaString.split('"');
      const ano = fechaResult[2].slice(1, -1);
      const mes =
        parseInt(fechaResult[4].slice(1, -1)) < 10
          ? '0' + fechaResult[4].slice(1, -1)
          : fechaResult[4].slice(1, -1);
      const dia =
        parseInt(fechaResult[6].slice(1, -1)) < 10
          ? '0' + fechaResult[6].slice(1, -1)
          : fechaResult[6].slice(1, -1);
      const fecha = ano + '-' + mes + '-' + dia;

      return fecha;
    }
  }
}
