<mat-card>
  <mat-card-content>
    <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="15px">
      <mat-form-field fxFlex appearance="outline">
        <mat-label for="tercero">Seleccione un Cliente</mat-label>
        <mat-select name="tercero" id="tercero" [(ngModel)]="cliente.id">

          <mat-option>
            <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" placeholderLabel="Buscar..." #buscar>
            </ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let cliente of clientes | filterBy: {nombre: buscar.value}" [value]="cliente.id">
            {{ cliente.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex appearance="outline">
        <mat-label for="interval">Número de Meses</mat-label>
        <input type="number" min="1" matInput name="interval" id="interval" [(ngModel)]="cliente.interval">
      </mat-form-field>

      <div fxFlex="10" fxLayoutAlign="start start">
        <button type="button" mat-fab color="primary" (click)="onExecuteFunctions()"
         matTooltip="Buscar" matTooltipPosition="above">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="15px"
     *ngIf="facturas$ | async as facturas">
      <app-list-invoice-analysis *ngIf="direcciones$ | async as direcciones" fxFlex="100" [facturas]="facturas" [direcciones]="direcciones"></app-list-invoice-analysis>
    </div>

  </mat-card-content>
</mat-card>