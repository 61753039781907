import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { ConsToken } from '@app/_services/constToken';

import { HttpClientService } from '../../_services/http_client_service/http-client.service';
import { Respuesta } from '../../_interfaces/respuesta';
import { PqrsInteraccionesType } from '../types/pqrsInteracciones.type';
import { ResponsablesPqrsType } from '../types/responsablesPqrs.type';
import { AlertaPqrsType } from '../types/alertaPqrs.type';
import { PqrsType } from '../types/pqrs.type';

@Injectable({
  providedIn: 'root'
})
export class PqrsService {

  private responsablesPqrsList$ = new Subject<ResponsablesPqrsType[]>();
  private listAlertPqrs$ = new Subject<AlertaPqrsType[]>();
  private alertPqrsEdit$ = new Subject<any>();
  private fechaEstimadaCierrePqrs$ = new Subject<string>();
  private pqrsList$ = new Subject<PqrsType[]>();

  constructor(private http: HttpClientService) { }

  savePqrs(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePersonas + '/pqrs', data);
  }

  getPqrsToSolve(persona: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/pqrs/getPqrsToSolve/${persona}/${socioPacciolo}`);
  }

  archivePqrs(data: any): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServicePersonas + '/pqrs/archivePqrs', data);
  }

  savePqrsInteraction(data: PqrsInteraccionesType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePersonas + '/pqrs/savePqrsInteraction', data);
  }

  alertasPqrs(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlNode + '/pqrs/enviarAlertas');
  }

  buscarTipoContacto(data: any): Observable<Respuesta> {
    return this.http.get(ConsToken.urlNode + '/pqrs/buscarTipoContacto/' + data);
  }

  buscarCorreosEmpleado(data: any): Observable<Respuesta> {
    return this.http.get(ConsToken.urlNode + '/pqrs/buscarCorreosEmpleado/' + data);
  }

  getPqrsHistory(persona: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/pqrs/getPqrsHistory/${persona}/${socioPacciolo}`);
  }

  changeResponsiblePqrs(data: any): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServicePersonas + '/pqrs/changeResponsiblePqrs', data);
  }

  /**
   * @description
   * Función que elimina a una persona de la responsabilidad de solucionar una PQRS
   * @param id Contiene el ID de la entidad ResponsablesPqrs
   */
  deleteResponsablesPqrs(id: number): Observable<Respuesta> {
    return this.http.delete(ConsToken.urlMicroServicePersonas + `/pqrs/deleteResponsablesPqrs/${id}`);
  }

  getResponsiblesPqrs(id: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/pqrs/getResponsiblesPqrs/${id}`);
  }

  addResponsiblesPqrs(data: ResponsablesPqrsType[]): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePersonas + `/pqrs/addResponsiblesPqrs`, data);
  }

  /*###############################INICIO FUNCIONES QUE FUNCIONAN EN LOCAL####################################*/

  /**
   * @description
   * Función que envia el listado de responsable de una PQRS a todos los observer
   * pero de manera LOCAL
   * @param responsablesPqrsList Contiene un listado de responsables PQRS
   */
  sendResponsablePqrsList(responsablesPqrsList: ResponsablesPqrsType[]): void {
    this.responsablesPqrsList$.next(responsablesPqrsList);
  }

  /**
   * @description
   * Función que obtiene el listado de responsable de una PQRS
   * pero de manera LOCAL
   */
  getResponsablePqrsList(): Observable<ResponsablesPqrsType[]> {
    return this.responsablesPqrsList$.asObservable();
  }

  /**
   * @description
   * Función que envia el listado de alertas de una PQRS a todos los observer
   * pero de manera LOCAL
   * @param listAlertPqrs Contiene un listado de alertas PQRS
   */
  sendListAlertPqrs(listAlertPqrs: AlertaPqrsType[]): void {
    this.listAlertPqrs$.next(listAlertPqrs);
  }

  /**
   * @description
   * Función que obtiene el listado de alertas de una PQR
   * pero de manera LOCAL
   */
  getListAlertPqrs(): Observable<AlertaPqrsType[]> {
    return this.listAlertPqrs$.asObservable();
  }

  /**
   * @description
   * Función que envia la fecha estimada de cierre de una pqrs a todos los observer
   * pero de manera LOCAL
   * @param fechaEstimadaCierrePqrs Contiene la fecha estimada de cierre de una pqrs
   */
  sendFechaEstimadaCierrePqrs(fechaEstimadaCierrePqrs: string): void {
    this.fechaEstimadaCierrePqrs$.next(fechaEstimadaCierrePqrs);
  }

  /**
   * @description
   * Función que obtiene la fecha estimada de cierre de una pqrs
   * pero de manera LOCAL
   */
  getFechaEstimadaCierrePqrs(): Observable<string> {
    return this.fechaEstimadaCierrePqrs$.asObservable();
  }

  /**
   * @description
   * Función que envia la información de la alerta que se va a editar a todos los observer
   * pero de manera LOCAL
   * @param data Contiene la información de una alerta y el indice en la tabla en que se encuentra
   */
  sendAlertPqrsEdit(data: any): void {
    this.alertPqrsEdit$.next(data);
  }

  /**
   * @description
   * Función que obtiene la información de la alerta que se va a editar
   * pero de manera LOCAL
   */
  getAlertPqrsEdit(): Observable<any> {
    return this.alertPqrsEdit$.asObservable();
  }

  /**
   * @description
   * Función que envia un listado de pqrs a todos los observer
   * @param pqrsList Contiene un listado de pqrs 
   */
  sendPqrsList(pqrsList: PqrsType[]): void {
    this.pqrsList$.next(pqrsList);
  }

  /**
   * @description
   * Función que obtiene un listado de pqrs pero de manera LOCAL
   */
  getPqrsList(): Observable<PqrsType[]> {
    return this.pqrsList$.asObservable();
  }
}
