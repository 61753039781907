
<div fxLayout="row wrap" class="margin-top-20" fxLayout.lt-md="column wrap" fxLayoutGap="15px">

  <mat-form-field fxFlex appearance="outline" *ngIf="dataSource.data.length > 0" style="margin-right: 50%;">
    <mat-label>Buscar por Nombre</mat-label>
    <input matInput (keyup)="applyFilter($event.target.value)">
  </mat-form-field>

  <div fxFlex="100" fxLayout="column wrap" class="responsive_table" style="overflow-y: hidden;">

    <table fxFlex="100" mat-table [dataSource]="dataSource" *ngIf="dataSource.data.length > 0" matSort>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper text-center"> Nombre </th>
        <td mat-cell *matCellDef="let element">
          {{ element.nombre }}
        </td>
      </ng-container>

      <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper text-center"> Accion </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="copyText(element.nombre)" matTooltip="Copiar" matTooltipPosition="above">
            <mat-icon>file_copy</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    

  </div>
</div>


<mat-paginator #paginador *ngIf="dataSource.data.length > 0" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>