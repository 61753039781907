import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FacturaBlock } from '@app/Clases/Factura/factura-block';
import { FacturaType } from '@app/facturacion/types/factura.types';
import { DireccionType } from '@app/persona/types/direccion.type';
import { RecoleccionType } from '@app/redAmbiental/types/recoleccion.type';
import { ContratoType } from '@app/_interfaces/gestion_contratos/contrato.type';
import { MomentService } from '@app/_services/moment/moment.service';
import { ContratosService } from '@app/_services/contratos_service/contratos.service';

@Component({
  selector: 'app-list-invoice-analysis',
  templateUrl: './list-invoice-analysis.component.html',
  styleUrls: ['./list-invoice-analysis.component.scss']
})
export class ListInvoiceAnalysisComponent implements OnInit, OnChanges {

  @Input() facturas: FacturaType[] = [];
  @Input() direcciones: DireccionType[] = [];
  @Input() contratos: ContratoType[] = [];

  percent = 0.3
  total:any = 0 
  value = []
  alldata =[]
  data = []
  mediaInvoice: any = [];
  mediaHarvest: any = []
  totalHarvest: any = []
  mediana: FacturaType[] = [];

  constructor(
    private momentService: MomentService,
    private contratoService: ContratosService
  ) { }

  /**
   * @description
   * Función que retorna el nombre de un mes en base a la fecha
   * @param fecha Contiene una fecha
   * @returns Retorna el nombre de un mes
   */
  getMonthName(fecha: string): string {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return `${meses[this.momentService.month(fecha)]} de ${this.momentService.year(fecha)}`;
  }

  /**
   * @description
   * Función que calcula el total de las recolecciones de una dirección por mes
   * @param fecha Contiene una fecha de una factura
   * @param direccion Contiene la información de una dirección
   * @returns Un number
   */
  getHarvestMonth(fecha: string, direccion: DireccionType): number {
    const totalHarvest = this.calculateTotalHarvest(fecha, direccion);
    return totalHarvest;
  }

  /**
   * @description
   * Función que calcula el total de la recolección de una dirección
   * en base al mes y al año de la factura
   * @param fecha Contiene una fecha de una factura
   * @param direccion Contiene la información de una dirección
   * @returns Un number
   */
  calculateTotalHarvest(fecha: string, direccion: DireccionType): number {
    fecha = this.momentService.subtract(fecha, 1, 'month').format('YYYY-MM-DD');

    const totalHarvest = direccion.recolecciones
      .filter((reco: RecoleccionType) =>
        this.momentService.month(fecha) === this.momentService.month(reco.fechaRecoleccion) &&
        this.momentService.year(fecha) === this.momentService.year(reco.fechaRecoleccion))
      .reduce((acc: number, cur: RecoleccionType) =>
        acc + cur.anatomopatologico + cur.biosanitarios + cur.cortopunzantes + cur.farmacos +
        cur.mercuriales + cur.metales + cur.reactivos + cur.mezclados + cur.otros, 0);

    return totalHarvest;
  }

  /**
   * @description
   * Función que calcula el total de las recolecciones teniendo en cuenta
   * todas las direcciones y en base a la fecha de la factura
   * @param fecha Contiene el valor de una fecha
   * @returns Un number
   */
  getTotalHarvestByMonth(fecha: any): number {

    let total = 0;

    for (const direccion of this.direcciones) {
      total += this.calculateTotalHarvest(fecha, direccion);
    }
    return total;
  }

  getMediaHarvest(fecha: any): number{

    let valor = 0 ;
    for(const data of this.direcciones){
      valor += this.calculateTotalHarvest(fecha, data)

    }
    return valor
    
  }

  getAbsoluteValue(){
    let data = this.total
    let resdata = data * 0.3;
    resdata = (resdata / data)*100;
    let v1:any = []
    let v2:any = []
    let val:any = [];
    let dat:any = [];
    for(let element of this.facturas){
      dat.push(element.valorEstaFactura - data)
    }
    for(let element of dat){
      val.push(Math.abs(element))
    }
    for(let element of val){
      v1.push((element/data))
    }
    this.data = v1;
    console.log(this.data,'data')
    
    for (let element of v1){
      if(element < this.percent ){
        return 1
      }else{
        return 0
      }
    }

    console.log(v1, 'v1')

  }

  getMediaInvoice(facturas: any) {

    const cont = facturas;
    console.log(cont,'cont')
    let cant = facturas.length;
    let allData = facturas.sort((a, b) => a.valorEstaFactura - b.valorEstaFactura);
    let v1 = []
    for (let element of allData) {
      v1.push(element.valorEstaFactura)
    }
    let total;

     if(cant % 2 == 0 ){
      cant = cant/2
      const cant2 = cant-1
      const pos = v1[cant]
      const pos2 = v1[cant2]
      this.total = ((pos) + (pos2))/2
    } else {
      cant = (cant+1)
      const cant2 = (cant/2)-1
      const pos = v1[cant2]
      this.total = ((pos))
    }
    return this.total

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    { this.getMediaInvoice(this.facturas) }
    this.getMediaHarvest(changes)
    this.getAbsoluteValue()
  }



  ngOnInit(): void {
      
  }
}
