import { Injectable } from '@angular/core';
import { Session } from '@app/_class/session';

import { HttpClientService } from './http_client_service/http-client.service';
import { ConsToken } from './constToken';

// tslint:disable:semicolon
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private http: HttpClientService
  ) { }

  login = (credenciales: any) => this.http.login(ConsToken.urlNode + '/sesion/iniciar', credenciales);

  login2(credenciales: any): any {
    return this.http.post(ConsToken.urlEntryPoint + '/auth/login', credenciales);
  }

  logout(): any {
    const token = { token: Session.getItem('refresh_token') };
    return this.http.login(ConsToken.urlNode + '/sesion/cerrar', token);
  }

  renovar(): any {
    const token = { refresh_token: Session.getItem('refresh_token') };
    return this.http.login(ConsToken.urlNode + '/sesion/renovar', token);
  }
}
