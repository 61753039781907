import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { HttpClientService } from '@app/_services/http_client_service/http-client.service';
import { ConsToken } from '@app/_services/constToken';

import { Respuesta } from '@app/_interfaces/respuesta';
import { ResolucionFacturaType } from '@app/facturacion/types/resolucionFactura.type';

@Injectable({
  providedIn: 'root'
})
export class ResolucionFacturacionService {

  constructor(
    private http: HttpClientService
  ) { }

  getResolution(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePagosFactura + `/resolucion-facturacion/getResolution`)
  }

  saveResolution(resolution: ResolucionFacturaType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + `/resolucion-facturacion/saveResolution`, resolution)
  }
}
