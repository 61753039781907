<div fxFlex="100" class="responsive_table" style="overflow-y: hidden;">
  <table class="table">
    <thead>
      <tr>
        <th class="upper">Nombre</th>
        <th class="upper">Tipo y Numero ID</th>
        <th class="upper">Condicion de Direccion y ciudad</th>
        <th class="upper">Email</th>
      </tr>
    </thead>
    <tbody>
      <tr >
       
      </tr>
    </tbody>
  </table>
</div>