import { Component, Input, OnChanges, OnInit, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { DireccionType } from '@app/persona/types/direccion.type';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-form-solo-nombre',
  templateUrl: './form-solo-nombre.component.html',
  styleUrls: ['./form-solo-nombre.component.scss']
})
export class FormSoloNombreComponent implements OnInit, OnChanges {

  @Input() direccionList: DireccionType 
  @Output() sendDireccion = new EventEmitter<DireccionType>();


  formData = this.fb.group({
    direccion:['']
  })

  constructor(
    private fb: FormBuilder
  ) { }


  onSendAddress(): void {
    this.sendDireccion.emit(this.formData.get('direccion').value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changeDireccionList(changes)
  }

  ngOnInit(): void {
  }

  private changeDireccionList(change: SimpleChanges): void {
    if (change.direccionList && !change.direccionList.firstChange) {
      this.direccionList = change.direccionList.currentValue;
    }
  }

}
