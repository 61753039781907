import { Injectable } from '@angular/core';

import { HttpClientService } from '../http_client_service/http-client.service';
import { ConsToken } from '../constToken';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  user = {};
  constructor(private http: HttpClientService) { }

  urlBase64Decode(str: string): any {
    let output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw new Error('Illegal base64url string!');
    }
    return window.atob(output);
  }

  userData(): any {
    const token = localStorage.getItem('token');
    this.user = {};

    if (typeof token !== 'undefined' && token !== null) {
      const encoded = token.split('.')[1];
      this.user = JSON.parse(this.urlBase64Decode(encoded));
    }
    // console.log(this.user);
    return this.user;
  }

  getData = function(): any {
    const token = localStorage.getItem('token');
    this.user = {};

    if (typeof token !== 'undefined' && token !== null) {
      const encoded = token.split('.')[1];
      this.user = JSON.parse(this.urlBase64Decode(encoded));
    }
    // // console.log(this.user);
    return this.user;
  };

  buscarInfoUsuario(): any {
    return this.http.get(ConsToken.urlNode + '/user/buscarInfoUser');
  }

  onGuardarInfoGeneral(data: any): any {
    return this.http.put(ConsToken.urlNode + '/user/onGuardarInfoGeneral', data);
  }

  guardarEmail(data: any): any {
    return this.http.post(ConsToken.urlNode + '/user/guardarEmail', data);
  }

  elimicarCorreo(data: any): any {
    return this.http.put(ConsToken.urlNode + '/user/elimicarCorreo', data);
  }

  guardarDirecciones(data: any): any {
    return this.http.post(ConsToken.urlNode + '/user/guardarDirecciones', data);
  }

  eliminarDireccion(data: any): any {
    return this.http.put(ConsToken.urlNode + '/user/eliminarDireccion', data);
  }

  guardarTelefono(data: any): any {
    return this.http.post(ConsToken.urlNode + '/user/guardarTelefono', data);
  }

  eliminarTelefono(data: any): any {
    return this.http.put(ConsToken.urlNode + '/user/eliminarTelefono', data);
  }

  cambiarPassword(data: any): any {
    return this.http.put(ConsToken.urlNode + '/user/cambiarPassword', data);
  }

  cambiarUsuario(data: any): any {
    return this.http.put(ConsToken.urlNode + '/user/cambiarUsuario', data);
  }

  cambiarImgPerfil(data: any): any {
    return this.http.put(ConsToken.urlNode + '/user/cambiarImgPerfil', data);
  }

  cambiarImgFondo(data: any): any {
    return this.http.put(ConsToken.urlNode + '/user/cambiarImgFondo', data);
  }

  buscarFotosMasterMaterial(): any {
    return this.http.get(ConsToken.urlNode + '/user/buscarFotosMasterMaterial');
  }
}
