import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { TruncateModule } from 'ng2-truncate';
import { NgxMaskModule } from 'ngx-mask';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { NumbersDirective } from './_directives/numbers.directive';
import {
  ProvidersPipe,
  FilterPipe,
  SortByPipe,
  FilterCode,
  FilterTP,
  FilterGroup,
  FilterSelectLP,
  FilterCodLP,
  FilterNameLP,
  CedulaPipe,
  FilterDiaDescanso,
  FilterTipoPersona,
  FilterNamePersona,
  FilterIdPersona,
  FilterFecha,
  CaracteristicasLP,
  SanitizeHtmlPipe,
  Mister,
  FilterSucursal,
  FormatAddress,
  GetAddressType,
  WasteAbbreviation,
  TotalResidues,
  CalculatePercentage,
  SumAllValueOfArray
} from './_pipes/providers.pipe';

// import { InteraccionesPqrsComponent } from './pqrs/interacciones-pqrs/interacciones-pqrs.component';
import { ViewerPdfComponent } from './funtionalComponents/viewer-pdf/viewer-pdf.component';
import { ModalViewPdfComponent } from './funtionalComponents/modal-view-pdf/modal-view-pdf.component';
import { InteraccionesPqrsComponent } from './pqrs/components/smart/interacciones-pqrs/interacciones-pqrs.component';

@NgModule({
  declarations: [
    NumbersDirective,
    ProvidersPipe,
    SortByPipe,
    FilterPipe,
    FilterSucursal,
    FilterCode,
    FilterTP,
    FilterGroup,
    FilterSelectLP,
    FilterCodLP,
    FilterNameLP,
    FilterDiaDescanso,
    FilterTipoPersona,
    CedulaPipe,
    FilterNamePersona,
    FilterIdPersona,
    CaracteristicasLP,
    FilterFecha,
    FilterTipoPersona,
    SanitizeHtmlPipe,
    Mister,
    InteraccionesPqrsComponent,
    ViewerPdfComponent,
    ModalViewPdfComponent,
    FormatAddress,
    GetAddressType,
    WasteAbbreviation,
    TotalResidues,
    CalculatePercentage,
    SumAllValueOfArray
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    UiSwitchModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSortModule,
    MatTableModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatMomentDateModule,
    NgScrollbarModule,
    FlexLayoutModule,
    MatTreeModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxMaskModule.forRoot(),
    NgxMatSelectSearchModule,
    FilterPipeModule,
    TruncateModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' }],
  exports: [
    NgxMaskModule,
    CommonModule,
    ScrollingModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    FilterSucursal,
    FormsModule,
    NgSelectModule,
    UiSwitchModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatMomentDateModule,
    NumbersDirective,
    ProvidersPipe,
    FilterPipe,
    SortByPipe,
    FilterCode,
    FilterTP,
    FilterGroup,
    FilterSelectLP,
    FilterCodLP,
    FilterNameLP,
    CedulaPipe,
    MatTreeModule,
    FilterDiaDescanso,
    FlexLayoutModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    FilterTipoPersona,
    FilterNamePersona,
    FilterIdPersona,
    FilterFecha,
    SanitizeHtmlPipe,
    NgxMatSelectSearchModule,
    FilterPipeModule,
    TruncateModule,
    DragDropModule,
    Mister,
    FormatAddress,
    GetAddressType,
    WasteAbbreviation,
    TotalResidues,
    CalculatePercentage,
    SumAllValueOfArray,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule
  ]
})
export class ProviderModule {
}
