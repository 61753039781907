import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { HttpClientService } from '@app/_services/http_client_service/http-client.service';

import { ConsToken } from '@app/_services/constToken';
import { Respuesta } from '@app/_interfaces/respuesta';
import { CargosType } from '@app/persona/types/cargos.type';
import { FacturaType } from '@app/facturacion/types/factura.types';

@Injectable({
  providedIn: 'root'
})
export class SaldosInicialesService {

  constructor(private http: HttpClientService) { }

  saveInitBalance(invoice: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePagosFactura + '/factura/saveInitBalance', invoice)
  }
}
