import { Injectable } from '@angular/core';
import { UploadFileType } from '@app/_interfaces/uploadFile.type';

import { PDFDocument } from 'pdf-lib';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() { }

  /**
   * @description
   * Función que unifica archivos PDF y imagenes en un solo PDF
   * @param fileList Contiene un array de tipo UploadFileType
   * @param fileName Contiene un string
   * @returns any
   */
  async createOnePdf(fileList: UploadFileType[], fileName: string = 'pdf'): Promise<Blob> {
    try {

      const pdfDoc = await PDFDocument.create();
      

      for (const file of fileList) {

        if (file.type === 'pdf') {

          // Se carga el archivo pdf que quiero agregar al nuevo pdf
          const pdfLoad = await PDFDocument.load(file.value);

          // Se copian todas las hojas del pdf al nuevo pdf
          const copias = await pdfDoc.copyPages(pdfLoad, pdfLoad.getPageIndices());

          // Se agrega cada pagina del pdf al nuevo pdf
          copias.forEach((copia: any) => pdfDoc.addPage(copia));

        } else if (file.type === 'jpg' || file.type === 'jpeg') {

          // Se embebe la imagen en el pdf
          const jpgImage = await pdfDoc.embedJpg(file.value);

          // Se escala la imagen ancho 595 alto 900
          const jpgRescaled = jpgImage.scaleToFit(595, 900);

          // Se agrega una nueva hoja al pdf
          const page = pdfDoc.addPage();
          

          // Se pinta la imagen en la nueva hoja del pdf
          page.drawImage(jpgImage, {
            x: page.getWidth() / 2 - jpgRescaled.width / 2,
            y: page.getHeight() / 2 - jpgRescaled.height / 2,
            width: jpgRescaled.width,
            height: jpgRescaled.height
          });

        } else if (file.value) {

          // Se embebe la imagen en el pdf
          const pngImage = await pdfDoc.embedPng(file.value);

          // Se escala la imagen ancho 595 alto 900
          const pngRescaled = pngImage.scaleToFit(595, 900);

          // Se agrega una nueva hoja al pdf
          const page = pdfDoc.addPage();

          // Se pinta la imagen en la nueva hoja del pdf
          page.drawImage(pngImage, {
            x: page.getWidth() / 2 - pngRescaled.width / 2,
            y: page.getHeight() / 2 - pngRescaled.height / 2,
            width: pngRescaled.width,
            height: pngRescaled.height
          });
        }
      }

      // Se establece el nombre del pdf
      pdfDoc.setTitle(fileName);

      // Se crea el pdf
      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      return blob;
    } catch (error) {
      throw new Error(error);
    }
  }
}
