import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Session } from '@app/_class/session';
@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(private httpClient: HttpClient) { }

  // httpOptions = () => ({
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     // user: Session.getItem('usuario') ? Session.getItem('usuario').replace(/['"]+/g, '') : 'crash',
  //     'Authorization': 'Bearer ' + (Session.getItem('access_token') ? Session.getItem('access_token') : 'crash')
  //   })
  // })

  get(url: string): any {
    return this.httpClient.get(url);
  }

  getParam(url: string, parameters: any): any {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({
        user: Session.getItem('usuario'),
        authorization: Session.getItem('access_token')
      }),
      params: parameters
    });
  }

  login(url: string, data: any): any {
    return this.httpClient.post(url, data, {
      headers: new HttpHeaders({
        ignoreLoadingBar: ''
      })
    });
  }

  post(url: string, data: any): any {
    return this.httpClient.post(url, data);
  }

  put(url: string, data: any = null): any {
    return this.httpClient.put(url, data);
  }

  delete(url: string): any {
    return this.httpClient.delete(url);
  }
}
