// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Add here your keycloak setup infos
export const environment = {
  production: false,
  img_perfil: null,
  img_fondo: null,
  socioPacciolo: JSON.parse(sessionStorage.getItem('usuario')) ? JSON.parse(sessionStorage.getItem('usuario')).empresa[0].id : null,
  persona: JSON.parse(sessionStorage.getItem('usuario')) ? JSON.parse(sessionStorage.getItem('usuario')).id : null,
  pageSizeOptions: [10, 25, 50, 75, 100],
  companyFullName: JSON.parse(sessionStorage.getItem('usuario')) ? JSON.parse(sessionStorage.getItem('usuario')).empresa[0].companyFullName : null,
  descripcionList: [
    { socioPacciolo: 2, url: 'assets/descripcionFactura.txt' },
    { socioPacciolo: 3, url: 'assets/descripcionFacturaRP.txt' },
    { socioPacciolo: 4, url: 'assets/descripcionFacturaRPN.txt' }
  ],
  observacionList: [
    { socioPacciolo: 2, url: 'assets/observacionFactura.txt' },
    { socioPacciolo: 3, url: 'assets/observacionFacturaRP.txt' },
    { socioPacciolo: 4, url: 'assets/observacionFacturaRPN.txt' }
  ],
  firmasInvoices: [
    { socioPacciolo: 2, url: 'assets/firma3.txt' },
    { socioPacciolo: 3, url: 'assets/firma3.txt' },
    { socioPacciolo: 4, url: 'assets/firma-rp-rpn.txt' }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
