import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecursoService {
  constructor() { }

  filterAutoComplete(val: string, object: any, propiedad: string): void {
    if (val) {
      val = val.toLocaleLowerCase();
      return object.filter((state: any) =>
        state[propiedad].toLocaleLowerCase().startsWith(val)
      );
    }
    return object;
  }

  filterAnyone(val: string, object: any, propiedad: string): any {
    // Busca cualquiera coincidencia

    if (val) {
      val = val.toLocaleLowerCase();
      return object.filter((state: any) =>
        state[propiedad].toLocaleLowerCase().includes(val)
      );
    }

    return object;
  }

  formatFecha(valor: any): string {
    if (valor) {
      valor = valor.format('YYYY-MM-DD');
      return valor;
    }
  }

  holaRecuService(): string {
    return `hola mundito`;
  }

  imprimirValor(valor: any, object: any): any {
    if (valor) {
      valor = valor.toLocaleLowerCase();
      return object.filter((element: any) => {
        element['sucursal'].toLocaleLowerCase().includes(valor);
      });
    }

  }

  applyFilter(object: any, value: any): any {
    object.filter = value.trim().toLowerCase();
    return object;
  }
}
