import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Session } from '@app/_class/session';

import { AuthenticationService } from '../../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private router: Router, private _servicio: AuthenticationService) {}

  canActivate(): any {
    if (Session.logueado()) {
      return true;
    } 
    // console.log('Bloqueado por guard!');
    this.router.navigate(['login']);
    return false;
  }
}
