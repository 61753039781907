<div fxFlex="100" class="responsive_table" style="overflow-y: hidden;">
  <table class="table">
    <thead>
      <tr>
        <th class="upper">Nombre</th>
        <th class="upper">Valor</th>
        <th class="upper">Condicion de pago</th>
        <th class="upper">Tipo</th>
      </tr>
    </thead>
    <tbody>
      <tr >
       
      </tr>
    </tbody>
  </table>
</div>