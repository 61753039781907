import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class MomentService {

  constructor() {
    momentTimezone.locale('es');
    momentTimezone.tz('America/Bogota');
  }

  /**
   * @description
   * Función que retorna un objecto de tipo fecha
   * @param fecha Contiene una fecha
   * Si el parametro fecha no contiene una fecha valida se  utiliza la fecha actual
   * @returns Un momentTimezone.Moment
   */
  newDate(fecha?: momentTimezone.MomentInput): moment.Moment {
    if (momentTimezone(fecha).isValid()) {
      return momentTimezone(fecha);
    }
    return momentTimezone();
  }

  /**
   * @description
   * Función que retorna el año de una fecha
   * @param fecha Contiene una fecha de tipo moment.MomentInput
   * @returns Un number
   */
  year(fecha?: momentTimezone.MomentInput): number {
    if (fecha) {
      return this.newDate(fecha).year();
    }

    return this.newDate().year();
  }

  /**
   * @description
   * Función que retorna el mes de una fecha
   * @param fecha Contiene una fecha de tipo moment.MomentInput
   * @returns Un number
   */
  month(fecha?: momentTimezone.MomentInput): number {
    if (fecha) {
      return this.newDate(fecha).month();
    }

    return this.newDate().month();
  }

  /**
   * @description
   * Función que retorna el dia de una fecha
   * @param fecha Contiene una fecha de tipo moment.MomentInput
   */
  date(fecha?: momentTimezone.MomentInput): number {
    if (fecha) {
      return this.newDate(fecha).date();
    }

    return this.newDate().date();
  }

  /**
   * @description
   * Función que agrega dias, meses semanas y años a una fecha
   * @param fecha Contiene una fecha de tipo moment.MomentInput
   * @param num Contiene el numero de Dias, semanas, meses, años que le va a agregar a la fecha
   * @param tipo Contiene un string ej: year, month, week, day.
   * encuentre más información en la documentación https://momentjs.com/docs/#/manipulating/add/
   */
  add(fecha: momentTimezone.MomentInput, num: moment.DurationInputArg1, tipo: moment.unitOfTime.DurationConstructor): moment.Moment {
    return momentTimezone(fecha).add(num, tipo);
  }

  /**
   * @description
   * Función que cambia el formato de una fecha
   * @param fecha Contiene una fecha
   * @param format Contiene el fotmato de la fecha. Valor por defecto (YYYY-MM-DD)
   */
  format(fecha: momentTimezone.MomentInput, format: string = 'YYYY-MM-DD'): string {
    return fecha ? momentTimezone(fecha).format(format) : null;
  }

  /**
   * @description
   * Función que sustrae dias, meses semanas y años a una fecha
   * @param fecha Contiene un fecha
   * @param num Contiene el numero de Dias, semanas, meses, años que le va a agregar a la fecha
   * @param tipo Contiene un string ej: year, month, week, day.
   * encuentre más información en la documentación https://momentjs.com/docs/#/manipulating/add/
   */
  subtract(fecha: momentTimezone.MomentInput, num: moment.DurationInputArg1, tipo: moment.unitOfTime.DurationConstructor): moment.Moment {
    return momentTimezone(fecha).subtract(num, tipo);
  }

  /**
   * @description
   * Función que valida si la fecha1 es menor a la fecha2
   * @param fecha1 Contiene el valor de una fecha
   * @param fecha2 Contiene el valor de una fecha
   * @return boolean
   */
  isBefore(fecha1: momentTimezone.MomentInput, fecha2: moment.MomentInput = moment()): boolean {
    return momentTimezone(fecha1).isBefore(fecha2);
  }

  /**
   * @description
   * Función que valida si la fecha1 es igual a la fecha2
   * @param fecha1 Contiene el valor de una fecha
   * @param fecha2 Contiene el valor de una fecha. Valor por defecto la fecha Actual
   * @return boolean
   */
  isSame(fecha1: momentTimezone.MomentInput, fecha2: moment.MomentInput = moment()): boolean {
    return momentTimezone(fecha1).isSame(fecha2);
  }

  /**
   * @description
   * Función que valida si la fecha1 es mayor a la fecha2
   * @param fecha1 Contiene el valor de una fecha
   * @param fecha2 Contiene el valor de una fecha. Valor por defecto la fecha Actual
   * @return boolean
   */
  isAfter(fecha1: momentTimezone.MomentInput, fecha2: moment.MomentInput = moment()): boolean {
    return momentTimezone(fecha1).isAfter(fecha2);
  }

  /**
   * @description
   * Función que valida si la fecha1 es mayor o igual a la fecha2
   * @param fecha1 Contiene el valor de una fecha
   * @param fecha2 Contiene el valor de una fecha. Valor por defecto la fecha Actual
   * @return boolean
   */
  isSameOrAfter(fecha1: momentTimezone.MomentInput, fecha2: moment.MomentInput = moment()): boolean {
    return momentTimezone(fecha1).isSameOrAfter(fecha2);
  }

  /**
   * @description
   * Función que valida si la fecha1 es menor o igual a la fecha2
   * @param fecha1 Contiene el valor de una fecha
   * @param fecha2 Contiene el valor de una fecha. Valor por defecto la fecha Actual
   * @return boolean
   */
  isSameOrBefore(fecha1: momentTimezone.MomentInput, fecha2: moment.MomentInput = moment()): boolean {
    return momentTimezone(fecha1).isSameOrBefore(fecha2);
  }

  /**
   * @description
   * Función que compara si la fecha1 está entre la fecha2 y la fecha3
   * @param fecha1 Contiene el valor de una fecha
   * @param fecha2 Contiene el valor de una fecha. Debe ser menor que la fecha3
   * @param fecha3 Contiene el valor de una fecha. Debe ser mayor a la fecha2
   * @return boolean
   */
  isBetween(fecha1: momentTimezone.MomentInput, fecha2: moment.MomentInput, fecha3: moment.MomentInput): boolean {
    return momentTimezone(fecha1).isBetween(fecha2, fecha3);
  }

  /**
   * @description
   * Función que valida si una fecha es valida
   * @param fecha Contiene el valor de una fecha
   * @returns boolean
   */
  isValid(fecha: momentTimezone.MomentInput): boolean {
    return moment(fecha).isValid();
  }

  /**
   * @description
   * Función que obtiene el nombre del mes
   * @param fecha Contiene una fecha de tipo moment.MomentInput
   * @returns Un string
   */
  monthName(fecha: momentTimezone.MomentInput): string {

    const monthNumber = this.month(fecha);

    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
      'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    return months[monthNumber];
  }

  /**
   * @description
   * Función que obtiene el primer dia del mes
   * @param fecha Contiene una fecha de tipo moment.MomentInput
   */
  firstMonthDay(fecha: moment.MomentInput): string {
    return momentTimezone(fecha).startOf('months').format('YYYY-MM-DD');
  }

  /**
   * @description
   * Función que obtiene el primer dia del mes
   * @param fecha Contiene una fecha de tipo moment.MomentInput
   * @returns un string
   */
  lastMonthDay(fecha: moment.MomentInput): string {
    return momentTimezone(fecha).endOf('months').format('YYYY-MM-DD');
  }

  /**
   * @description
   * Función que retorna el primer día del año
   * @param fecha Contiene una fecha de tipo moment.MomentInput
   * @returns Un string
   */
  firstYearDay(fecha: moment.MomentInput): string {
    return this.newDate(fecha).startOf('year').format('YYYY-MM-DD');
  }

  /**
   * @description
   * Función que retorna el ultimo día del año
   * @param fecha Contiene una fecha de tipo moment.MomentInput
   * @returns Un string
   */
  lastYearDay(fecha: moment.MomentInput): string {
    return this.newDate(fecha).endOf('year').format('YYYY-MM-DD');
  }
}
