import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { Subject } from 'rxjs/Subject';

import { HttpClientService } from '@app/_services/http_client_service/http-client.service';

import { DireccionType } from '@app/persona/types/direccion.type';
import { Respuesta } from '@app/_interfaces/respuesta';
import { ConsToken } from '@app/_services/constToken';
import { BarrioType } from '@app/persona/types/barrio.type';
import { CiudadType } from '@app/persona/types/ciudad.type';
import { DepartamentoType } from '@app/persona/types/departamento.type';

@Injectable({
  providedIn: 'root'
})
export class DireccionService {

  private direccionList$ = new Subject<DireccionType[]>();

  /* Envia la dirección y el indice en el listado de direcciones */
  private direccionEdit$ = new Subject<any>();

  private direccionId$ = new Subject<number>();

  constructor(private http: HttpClientService) { }

  saveAddress(data: DireccionType): Observable<Respuesta> {
    return this.http.post(
      ConsToken.urlMicroServicePersonas + `/direccion/saveAddress`,
      data
    );
  }

  getDireccionesDeEmpresa(idSocio: number): any {
    return this.http.get(
      ConsToken.urlMicroServicePersonas + `/direccion/${idSocio}`
    );
  }

  getAddressClients(socioPacciolo: any): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getAddressClients/${2}`)
  }

  departamentosList(): Observable<DepartamentoType[]> {
    return this.http.get(ConsToken.urlMicroServicePersonas + '/personas/departamentosList')
      .pipe(
        map(element => {
          const respuesta = <Respuesta>element;
          return respuesta.data;
        })
      );
  }

  getDeparmentsByCountryId(countryId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/direccion/getDeparmentsByCountryId/${countryId}`);
  }

  /**
   * @description
   * Función que busca las ciudades dependiendo el departamento
   * @param departamentoId Contiene el ID de un departamento
   */
  getCitiesByDeparmentId(departamentoId: number): Observable<CiudadType[]> {
    return this.http.get(ConsToken.urlMicroServicePersonas + '/personas/ciudadesListById/' +
      departamentoId).pipe(
        map(element => {
          const respuesta = <Respuesta>element;
          return respuesta.data;
        }));
  }

  ciudadesList(): Observable<DireccionType> {
    return this.http.get(ConsToken.urlMicroServicePersonas + '/personas/ciudadesList').pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      }));
  }

  barrioList(ciudadId: number): Observable<DireccionType> {
    return this.http.get(ConsToken.urlMicroServicePersonas + '/crm/buscarBarrioEdificio/' + ciudadId).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data.barrio;
      })
    );
  }

  /**
   * @description
   * Función que actualiza una dirección
   * @param direccion Contiene un Objeto de tipo DireccionType
   * @returns Un Objeto de tipo Respuesta
   */
  updateAddres(direccion: DireccionType): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServicePersonas + '/direccion', direccion);
  }

  /**
   * @description
   * Funciónque obtiene los barrios por el ID de la ciudad
   * @param ciudadId Contiene el ID de una ciudad
   */
  getNeighborhoodsByCity(ciudadId: number): Observable<BarrioType> {
    return this.http.get(ConsToken.urlMicroServicePersonas + '/common/getNeighborhoodByCity/' + ciudadId);
  }

  /**
   * @description
   * Función que obtiene las direcciones para ser mostradas en un select
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  getAllAddressForSelect(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/direccion/getAllAddressForSelect/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que busca las direcciones para poder ser agregadas como infiltradas
   * en la vista crear rhps del modulo red ambiental
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  getInfiltratorsList(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/direccion/getInfiltratorsList/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que busca los nombres de las direcciones asociadas a una persona con un socio pasiolo
   *
   * Nota: Estas direcciones son para ser mostradas en campos de tipo select
   * @param personaId Contiene el ID de una persona
   * @param socioPacciolo Contiene el ID de un socio pasiolo
   * @returns Un array de tipo DireccionesInterfaces
   */
  getAddresNameForSelectByPerson(personaId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/direccion/getAddresNameForSelectByPerson/${personaId}/${socioPacciolo}`);
  }

   /**
   * @description
   * Función que busca los nombres de las direcciones asociadas a una persona con un socio pasiolo
   * @param personaId Contiene el ID de una persona
   * @param socioPacciolo Contiene el ID de un socio pasiolo
   * @returns Un array de tipo DireccionesInterfaces
   */
  getAddressByPerson(personaId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/direccion/getAddressByPerson/${personaId}/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que busca las direcciones tipo 1 y 2 para un select
   * @param socioPacciolo Contien el ID de un socioPacciolo
   */
  getAddressTypeOneTwoForSelect(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/direccion/getAddressTypeOneTwoForSelect/${socioPacciolo}`);
  }

  /*###############################INICIO DE FUNCIONES QUE FUNCIONAN EN LOCAL####################################*/

  updateIndexAddress(data: any): any {
    this.direccionId$.next(data);
  }

  /**
   * @description
   * Función que manda el id de la dirección que ha sido eliminada a los componentes
   * app-emails y app-telefonos
   */
  getAddressId(): Observable<any> {
    return this.direccionId$.asObservable();
  }

  /**
   * @description
   * Función que envia el listado de direcciones a todos los observer
   * pero de manera LOCAL
   * @param addressList Contiene un listado de direcciones
   */
  sendAddressList(addressList: DireccionType[]): void {
    this.direccionList$.next(addressList);
  }

  /**
   * @description
   * Función que obtiene el listado de direcciones pero de manera LOCAL
   */
  getAddressList(): Observable<DireccionType[]> {
    return this.direccionList$.asObservable();
  }

  /**
  * @description
  * Función que envia la información de la dirección que se va a editar
  * pero de manera LOCAL
  * @param address Contiene la información de la dirección que va a editar
  */
  sendAddressEdit(address: any): void {
    this.direccionEdit$.next(address);
  }

  /**
   * @description
   * Función que obtiene la dirección que se va a editar pero de manera LOCAL
   */
  getAddressEdit(): Observable<any> {
    return this.direccionEdit$.asObservable();
  }
}
