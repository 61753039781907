<div fxLayout="row wrap" class="responsive_table" style="overflow-y: hidden;">
  <table fxFlex id="reporte">
    <thead>
      <tr>
        <th></th>
        <th *ngFor="let factura of facturas">
          {{ getMonthName(factura.fechaExpedicion) }} 
        </th>
        <th>
          Mediana
        </th>
      </tr>
    </thead>
    <tbody>

      <tr>
        <td class="upper" >valor facturas</td>
        <td id="fac_td" *ngFor="let factura of facturas">
          <FONT *ngIf="getAbsoluteValue() == 1" color="black" >
            ${{ factura.valorEstaFactura | number }}
          </FONT>
          <FONT *ngIf="getAbsoluteValue() == 0" color="red" >
            ${{ factura.valorEstaFactura | number }}
          </FONT>
          
        </td>
        <td >
          ${{ getMediaInvoice(facturas) | number}}
        </td>
      </tr>

      <tr *ngFor="let direccion of direcciones; index as i">
        <td>
          <FONT *ngIf="direccion.estado == 'Inactiva'" color="red">
            {{ direccion.estado }} - {{ direccion.nombre }}
          </FONT>
          <FONT *ngIf="direccion.estado == 'Activa'" color="black">
            {{ direccion.estado }} - {{ direccion.nombre }}
          </FONT></td>
        <td *ngFor="let factura of facturas">
          {{ getHarvestMonth(factura.fechaExpedicion, direccion) }}
        </td>
        <td>
          {{ mediaHarvest }}
        </td>
      </tr>
    </tbody>

    <tfoot>
      <td class="upper" id="totales">totales: </td>
      <td *ngFor="let factura of facturas">
        {{ getTotalHarvestByMonth(factura.fechaExpedicion) }}
      </td>
    </tfoot>
  </table>
</div>