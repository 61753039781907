import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Respuesta } from '@app/_interfaces/respuesta';

import { HttpClientService } from '../http_client_service/http-client.service';
import { ConsToken } from '../constToken';

@Injectable({
  providedIn: 'root'
})
export class FacturacionElectronicaService {

  constructor(private http: HttpClientService) { }

  /**
   * @description
   * Función que genera electronicamente una factura
   * @param facturaId Contiene un number
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
  generateElectronicInvoice(facturaId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceFacturaElectronica + `/uploadInvoiceFile/${facturaId}/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que genera electronicamente las facturas en bloque
   * @param consecutivoFuenteId Contiene un number 
   * @param socioPacciolo Contiene el ID de un socioPacciolo
   */
   getInvoicesFromBulkBilling(consecutivoFuenteId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceFacturaElectronica + `/getInvoicesFromBulkBilling/${consecutivoFuenteId}/${socioPacciolo}`);
  }

  /**
   * @description
   * Función que obtiene el PDF de una factura electronica
   * @param prefijo Contiene un string
   * @param folio Contiene un string 
   * @param socioPacciolo Contiene le ID de un socioPacciolo
   */
  downloadPDF(prefijo: string, folio: string, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceFacturaElectronica + `/downloadPDFFile/${prefijo}/${folio}/${socioPacciolo}`);
  }
}
