<form #formCargo="ngForm" [formGroup]="formData">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="10px">

        <mat-form-field fxFlex appearance="outline">
          <mat-label for="direccionId">Seleccione un Cliente</mat-label>
          <mat-select id="direccionId" name="direccionId" formControlName="direccionId"  (change)="onSendData()"
            required>
    
            <mat-option>
              <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" placeholderLabel="Buscar..." #search
                name="addressClient">
              </ngx-mat-select-search>
            </mat-option>
    
            <mat-option *ngFor="let address of addressList | filterBy: {nombre: search.value}" [value]="address.id">
              {{ address.nombre | uppercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline">
          <mat-label for="fechaExpedicion">Fecha Ultimo pago</mat-label>
          <input matInput readonly id="fechaExpedicion" name="fechaExpedicion" [matDatepicker]="picker"
            formControlName="fechaExpedicion" (select)="onSendData()" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker startView='month'></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" >
          <mat-label for="descripcion">Descripción</mat-label>
          <textarea matInput maxlength="255" mat-autosize id="descripcion" name="descripcion" formControlName="descripcion"
            #descripcion (change)="onSendData()" style="max-height: 50%;"></textarea>
          <mat-hint>La descripción debe tener máximo 255. <br>
            {{ descripcion.value.length }}/255
          </mat-hint>
        </mat-form-field>
        </div>
        <div style="max-width: 33%;">
          <mat-form-field fxFlex appearance="outline">
            <mat-label for="deudaActualCliente">Deuda Actual Cliente</mat-label>
            <input matInput id="deudaActualCliente" name="deudaActualCliente" formControlName="deudaActualCliente" prefix="$COP " mask="separator.2"
              thousandSeparator="," (change)="onSendData()" required/>
          </mat-form-field>
           
        </div>

    </mat-card-content>
  </mat-card>
</form>