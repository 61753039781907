<mat-card>
  <mat-card-header>
    <mat-card-title>
      Registro Contrato Laboral 
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div mat-dialog-content style="overflow-x: hidden;">
      <div fxFlex="100%">

        <div style="margin-top: 1%; max-width: 30%;" fxLayoutGap="20px">
          <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">

            <mat-form-field fxFlex appearance="outline">
              <mat-label for="socioPacciolo"> Empleado</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                
                  <mat-option *ngFor="let element of addressList | filterBy: {nombre: search.value}" [value]="element.id">
                    {{ element.nombre | uppercase }}
                  </mat-option>
              </mat-select>
            </mat-form-field>

            <button mat-fab color="primary">
              <mat-icon>add</mat-icon>
            </button>

          </div>
        </div>

        <!-- 1.1 -->

        <div>
          <app-empleado-list>

          </app-empleado-list>
        </div>

        <br>
        <br>
        <br>

        <div style="margin-top: 1%; margin-left: 1%;" fxLayoutGap="20px">
          <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">
            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="socioPacciolo"> Tipo de Contrato</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                </mat-option>
              </mat-select>
            </mat-form-field>

            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="fechaNacimiento">
                Fecha de Firma
              </mat-label>
              <input matInput readonly id="fechaNacimiento" name="fecha_nacimiento" [matDatepicker]="picker"
                name="fechaNacimiento">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker startView='multi-year'></mat-datepicker>
            </mat-form-field>

            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="fechaInicio">
                Fecha Inicio
              </mat-label>
              <input matInput readonly id="fechaNacimiento" name="fecha_nacimiento" [matDatepicker]="picker2"
                name="fechaNacimiento">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 startView='multi-year'></mat-datepicker>
            </mat-form-field>

            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="fechaFin">
                Fecha Fin
              </mat-label>
              <input matInput readonly id="fechaNacimiento" name="fecha_nacimiento" [matDatepicker]="picker3"
                name="fechaNacimiento">
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3 startView='multi-year'></mat-datepicker>
            </mat-form-field>


          </div>
        </div>

        <br>
        <!-- 2 -->

        <div style="margin-top: 1%; margin-left: 1%;" fxLayoutGap="20px">
          <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="socioPacciolo"> E.P.S.</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar..." >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option>

                </mat-option>
                
              </mat-select>
            </mat-form-field>

            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="socioPacciolo"> Fondo de Pensiones</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                </mat-option>
              </mat-select>
            </mat-form-field>

            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="socioPacciolo"> Fondo Cesantias</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- 3 -->

        <div style="margin-top: 1%; margin-left: 1%;" fxLayoutGap="20px">
          <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="socioPacciolo"> A.R.L.</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                </mat-option>
              </mat-select>
            </mat-form-field>

            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="socioPacciolo"> Riesgo</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                </mat-option>
              </mat-select>
            </mat-form-field>

            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="socioPacciolo"> Caja Compensación</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- 4 -->

        <div style="margin-top: 1%; margin-left: 1%;" fxLayoutGap="20px">
          <div fxLayout.lt-md="column wrap" fxLayoutGap="1%">
            <div class="color-principal-text">
              Responsable:
            </div>

            <div>
              <mat-radio-button type="checkbox" class="example-margin"> Aporte salud empleador</mat-radio-button>
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <mat-radio-button type="checkbox" class="example-margin"> Sena</mat-radio-button>
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <mat-radio-button type="checkbox" class="example-margin"> ICBF</mat-radio-button>
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <mat-radio-button type="checkbox" class="example-margin"> MENSA</mat-radio-button>
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <mat-radio-button type="checkbox" class="example-margin"> Los Otros</mat-radio-button>

            </div>

          </div>
        </div>

        <!-- 5 -->

        <div style="margin-top: 1%; margin-left: 1%;" fxLayoutGap="20px">
          <div fxLayout.lt-md="column wrap" fxLayoutGap="1%">

            <mat-form-field style="width: 20%;" appearance="outline">
              <mat-label for="nombre"> Salario basico</mat-label>
              <input matInput type="text" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="socioPacciolo"> Frecuencia Pago</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <label class="color-principal-text">
              Marque si es Salario Integral: <input type="checkbox" />
            </label>

          </div>
        </div>

        <!-- 6 -->

        <div style="margin-top: 1%; margin-left: 1%;" fxLayoutGap="20px">
          <div fxLayout.lt-md="column wrap" fxLayoutGap="1%">

            <div class="color-principal-text">
              Otros Pagos:
            </div>

            <mat-form-field style="width: 20%;" appearance="outline">
              <mat-label for="nombrePago"> Nombre del Pago</mat-label>
              <input matInput type="text" id="nombre" name="nombre">
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <mat-form-field style="width: 20%;" appearance="outline">
              <mat-label for="nombre"> Valor</mat-label>
              <input matInput type="text" id="nombre" name="nombre">
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <mat-form-field style="width: 20%;" appearance="outline">
              <mat-label for="nombre"> Condición de pago</mat-label>
              <input matInput type="text" id="nombre" name="nombre">
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <mat-form-field appearance="outline" style="width: 20%;">
              <mat-label for="socioPacciolo"> Tipo</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                </mat-option>
              </mat-select>
            </mat-form-field>



            <button mat-fab color="primary">
              <mat-icon>add</mat-icon>
            </button>

          </div>
        </div>

        <!-- 6.1 -->

        <div style="margin-top: 1%; margin-left: 1%;" fxLayoutGap="20px">
          <div fxLayout.lt-md="column wrap" fxLayoutGap="1%">
            <app-op-nomina>

            </app-op-nomina>
          </div>
        </div>

        <!-- 7 -->

        <br>
        <br>


        <div style="margin-top: 1%; margin-left: 1%;" fxLayoutGap="20px">
          <div fxLayout.lt-md="column wrap" fxLayoutGap="1%">

            <div>
              <label class="color-principal-text">Horario:</label>
            </div>
          

          <div>
            
            <label class=""> Hora Ingreso</label>

            <mat-form-field style="width: 8%; margin-left: 7%;" appearance="outline">
              <mat-label for="nombre"> Lunes</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Martes</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Miercoles</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Jueves</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Viernes</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Sabado</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Domingo</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

          </div>

          <div>
            
            <label class=""> Hora Salida</label>

            <mat-form-field style="width: 8%; margin-left: 7%;" appearance="outline">
              <mat-label for="nombre"> Lunes</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Martes</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Miercoles</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Jueves</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Viernes</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Sabado</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

            <mat-form-field style="width: 8%; margin-left: 3%;" appearance="outline">
              <mat-label for="nombre"> Domingo</mat-label>
              <input matInput type="time" id="nombre" name="nombre">
            </mat-form-field>

          </div>

        </div>
        </div>



      </div>
    </div>
  </mat-card-content>
</mat-card>