<mat-card>
  <mat-card-header>
    
  </mat-card-header>
  <mat-card-content>
  <app-form-saldos-iniciales [addressList]="addressList" [resetForm]="resetForm" (sendDataForm)="getDataForm($event)">
  </app-form-saldos-iniciales>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="save()">Guardar</button>
  </mat-card-actions>
</mat-card>
