import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class Toast {
  constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

  open(errorText: string): void {
    this.zone.run(() => {
      const snackBar = this.snackBar.open(errorText, null, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      snackBar.onAction().subscribe(() => {
        snackBar.dismiss();
      });
    });
  }
}
