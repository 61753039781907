import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-viewer-pdf',
  templateUrl: './viewer-pdf.component.html',
  styleUrls: ['./viewer-pdf.component.scss']
})
export class ViewerPdfComponent implements OnInit {

  @Input() pdf: string;

  constructor() { }

  ngOnInit(): void { }
}
