<mat-form-field fxFlex="40" fxFlex.lt-md appearance="outline">
  <mat-label for="direccion">Seleccione una Dirección</mat-label>
  <mat-select name="direccion" id="direccion" formControlName="direccion"
    #direccion>
    <mat-option>
      <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" placeholderLabel="Buscar..." #searchDireccion
        name="searchDireccion">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let element of direccionList | filterBy: {nombre: searchDireccion.value}" [value]="element"
      matTooltipPosition="above">
      {{ element.nombre | uppercase }}      
    </mat-option>
  </mat-select>
</mat-form-field>