export class Session {
  static guardar(json: object): void {
    for (const key in json) {
      if (Array.isArray([json[key]]) || typeof json[key] === 'object') {
        sessionStorage.setItem(key, JSON.stringify(json[key]));
      } else {
        sessionStorage.setItem(key, json[key].replace(/['"]+/g, ''));
      }
    }
  }

  static cerrar(): void {
    sessionStorage.clear();
  }

  static logueado(): boolean {
    return !!sessionStorage.getItem('usuario');
  }

  static getItem(nombre: string): any {
    return sessionStorage.getItem(nombre);
  }

  static validar(): void {
    throw new Error('Method not implemented.');
  }
}
