<form (ngSubmit)="login()" #f="ngForm" class="login-page" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="column">
    <mat-card style="max-width: 400px;border-radius: 10px" class="mat-elevation-z10">
      <mat-card-header>
        <mat-card-title>
          <img class="responsive-img" id="img-form" src="../../assets/logos/LOGOPASIOLO-02.png" alt="">
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field appearance="fill">
          <mat-label>Usuario</mat-label>
          <input required matInput type="text" name="usuario" [(ngModel)]="form.username">
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Contraseña</mat-label>
          <input required matInput type="password" minlength="6" name="clave" [(ngModel)]="form.password">
          <mat-icon matSuffix>lock</mat-icon>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions align="center" style="margin-bottom: 18px">
        <!-- [ladda]="envio" -->
        <button style="font-family: Pacciolo" [disabled]="f.invalid" mat-raised-button color="primary" type="submit">Iniciar sesión</button>
      </mat-card-actions>
    </mat-card>
  </div>
</form>
  