/**
 * @description
 * Tipos de direcciones
 */
export const ADDRESSTYPES = [
  { name: 'Sede Principal', value: '1' },
  { name: 'Sede Secundaria sin contrato', value: '0' },
  { name: 'Sede Secundaria con contrato directo', value: '2' }
];

/* Estados de las direciones */
export const ADDRESSSTATUS = ['Activa', 'Inactiva'];

export const TIPOSIDENTIFICACION = [
  { id: 1, nombre: 'NIT', abreviacion: 'NIT' },
  { id: 2, nombre: 'Cédula de Ciudadanía', abreviacion: 'CC' },
  { id: 3, nombre: 'Cédula de Extranjería', abreviacion: 'CE' }
];
