import { Component, OnInit } from '@angular/core';
import { ResolucionFacturaType } from '@app/facturacion/types/resolucionFactura.type';
import { ResolucionFacturacionService } from '@app/facturacion/services/resolucion-factura/resolucion-facturacion.service';
import { Respuesta } from '@app/_interfaces/respuesta';
import { Swal } from '@app/_services/swal';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { Subject } from 'rxjs/Subject';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-layout-resolucion-facturacion',
  templateUrl: './layout-resolucion-facturacion.component.html',
  styleUrls: ['./layout-resolucion-facturacion.component.scss']
})
export class LayoutResolucionFacturacionComponent implements OnInit {

  data: [] = []
  resolution:any = [];
  invoiceList = [];
  dataresolution: ResolucionFacturaType = {};
  resetForm = false;

  socioPacciolo = environment.socioPacciolo;

  displayedColumns = ['personaId', 'numeroAutorizacion', 'prefijo', 'fechaInicio', 'fechaFin', 'tipoResolucion', 'numeroInicial', 'numeroFinal', 'estado'];
  
  constructor(private resolucionFacturacion: ResolucionFacturacionService) { }

  ngOnInit(): void {
   
  }

  onGetFormData(resolution: ResolucionFacturaType):void {
    this.resolution = resolution;
  }

  /* getData(id: any) {
    this.resolucion=[];
    this.resolucionFacturacionService.getResolution()
    .subscribe((resp: Respuesta) => {

      console.log(resp.data, 'respdata')
      if (resp.code === 200) {
        this.resolucion = resp.data;
      }else {
        Swal.alerta(resp.mensaje);
      }
      
    })
  } */

  

  showData() {
  }

}
