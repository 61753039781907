/* tslint:disable:variable-name */
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
// tslint:disable-next-line: import-blacklist
import { Observable } from 'rxjs';
import { Session } from '@app/_class/session';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    // const body = window.btoa(JSON.stringify(req.body));

    const authorization = Session.getItem('access_token')
      ? `Bearer ${Session.getItem('access_token').replace(/['"] + /g, '')}`
      : 'crash';
    const user_data = Session.getItem('usuario')
      ? Session.getItem('usuario').replace(/['"]+/g, '')
      : 'crash';

    if (req.url.includes('iniciar')) {
      // console.log('ENCRIPTAR USUARIO Y CLAVE');
    }

    const clone = req.clone({
      setHeaders: {
        Authorization: (authorization.replace(/\"/g, '')),
        user: user_data
      }
      // withCredentials : true,
      // body: body
    });
    // console.log("BODY", body)

    // console.log('CLONE_REQUEST', clone);
    return next.handle(clone);
  }
}
