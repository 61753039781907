import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable()
export class Swal {
  private static color1 = '#139873';
  private static color2 = '#ce0202';

  // tslint:disable-next-line: no-unused
  static eliminar(texto: string = '', callback: Function = (res: boolean) => { }): void {
    swal.fire({
      icon: 'question',
      title: '¿Estás seguro?',
      text: texto,
      showCancelButton: true,
      confirmButtonColor: this.color2,
      cancelButtonColor: this.color1,
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then(result => {
      callback(result.value);
    });
  }

  // tslint:disable-next-line: no-unused
  static pregunta(texto: string = '', callback: Function = (res: boolean) => { }): void {
    swal.fire({
      icon: 'question',
      title: '¿Estás seguro?',
      text: texto,
      showCancelButton: true,
      confirmButtonColor: this.color1,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then(result => {
      callback(result.value);
    });
  }

  static error(texto: string = ''): void {
    swal.fire({
      icon: 'error',
      title: 'Ops..!',
      text: texto,
      confirmButtonColor: this.color1,
      confirmButtonText: 'Cerrar'
    });
  }

  static alerta(texto: string = ''): void {
    swal.fire({
      icon: 'warning',
      title: '¡Advertencia!',
      text: texto,
      confirmButtonColor: this.color1,
      confirmButtonText: 'Aceptar'
    });
  }

  static correcto(texto: string = ''): void {
    swal.fire({
      icon: 'success',
      title: '¡Operación exitosa!',
      text: texto,
      confirmButtonColor: this.color1,
      confirmButtonText: 'Aceptar'
    });
  }

  static informacion(texto: string = ''): void {
    swal.fire({
      icon: 'info',
      title: '¡Información!',
      text: texto,
      confirmButtonColor: this.color1,
      confirmButtonText: 'Aceptar'
    });
  }

  static toast_correcto(texto: any = ''): void {
    swal.fire({
      icon: 'success',
      position: 'center',
      title: texto,
      showConfirmButton: false,
      timer: 1500
    });
  }

  static toast_alerta(texto: string = ''): void {
    swal.fire({
      icon: 'warning',
      position: 'center',
      title: texto,
      showConfirmButton: false,
      timer: 1500
    });
  }

  static toast_error(texto: string = ''): void {
    swal.fire({
      icon: 'error',
      position: 'center',
      title: texto,
      showConfirmButton: false,
      timer: 1500
    });
  }

  static toast_informacion(texto: string = ''): void {
    swal.fire({
      icon: 'info',
      position: 'center',
      title: texto,
      showConfirmButton: false,
      timer: 1500
    });
  }
}
