import { Component, OnInit, Input } from '@angular/core';
import { RrhhService } from '@app/rrhh/services/rrhh.service';
import { environment } from 'environments/environment';
import { TerceroType } from '@app/persona/types/tercero.type';

import { takeUntil } from 'rxjs/operators/takeUntil';
import { Respuesta } from '@app/_interfaces/respuesta';
import { Subject } from 'rxjs/Subject';
import { CommonService } from '@app/_services/common/common.service';
import { DireccionType } from '@app/persona/types/direccion.type';


@Component({
  selector: 'app-datos-empleados',
  templateUrl: './datos-empleados.component.html',
  styleUrls: ['./datos-empleados.component.scss']
})
export class DatosEmpleadosComponent implements OnInit {
  
  private stopSubscription$ = new Subject<void>();
  constructor(
    private serviCommon: RrhhService
  ) { }

  ngOnInit(): void {
    this.getInfoUpdatePerson()
    this.getEps();
    this.form.socioPacciolo = environment.socioPacciolo;
    console.log(environment.socioPacciolo, 'sociopa')
    console.log(this.addressList, 'data')
  }

  @Input() addressList: DireccionType[] = [];

  socioPacciolo = environment.socioPacciolo;



  form: TerceroType = {
    id: null, tipoPersonas: 'Natural', genero: null, fechaNacimiento: null, tipoIdentificacion: null, numeroIdentificacion: null,
    nombre: null, apellidos: null, nombreComercial: null, dv: null, usuarioCreacion: environment.persona,
    contacto: null, currentView: null, siglas: null, matriculaMercantil: null,
    relacionEntrePersona: {
      tipoRelacionId: null, privilegioId: null, socioPacciolo: this.socioPacciolo, estadoId: 1,
      duenoContRepre: null
    },
    composicionCapital: { id: null, personaId: null, socioPacciolo: this.socioPacciolo, tipo: null, tipo2: null },
    sectorEconomico: { id: null, personaId: null, socioPacciolo: this.socioPacciolo, tipo: null },
    usuario: { nombreUsuario: null },
    terceroEstadosClientesPotencial: { socioPacciolo: this.socioPacciolo, estadoClientePotencialId: null },
    actividadesEconomicas: [], direcciones: [], emails: [], telefonos: [], responsabilidadesCalidadesAtributos: [],
    obligacionesAduaneros: [], redesSociales: [], contrato: null
  };

  

  listEPS = [];
  listRelacionEntrePersonas = [];
  listEstados = [];
  listCargos = [];
  listCodeCiuus = [];
  listAuxCargos = [];
  listPaises = [];
  listCiudades = [];
  listDetalleDireccion = [];
  listTipoRelacionesEntrePersonas = [];
  listPrivilegios = [];
  tiposResponsabilidades = [];
  listEstadoClientePotencial = [];

  

  getInfoUpdatePerson(): void {
    this.serviCommon.getInfoConfigCreateUpdatePerson2(environment.socioPacciolo)
      .pipe(takeUntil(this.stopSubscription$))
      .subscribe((resp: Respuesta) => {
        this.listEstados = resp.data.estados;
        this.listAuxCargos = resp.data.cargos;
        this.listPaises = resp.data.paises;
        this.listCiudades = resp.data.paises
        this.listDetalleDireccion = resp.data.detalleDireccion;
        this.listTipoRelacionesEntrePersonas = resp.data.tipoRelacionesEntrePersonas;
        this.listRelacionEntrePersonas = resp.data.relacionesEntrePErsonas;
        this.listPrivilegios = resp.data.privilegios;
        this.tiposResponsabilidades = resp.data.tiposResponsabilidades;
        this.listEstadoClientePotencial = resp.data.estadoClientePotencial;
        console.log(this.listRelacionEntrePersonas, 'paises')
      })
  }

  getEps(): void {
     /* this.serviCommon.getEPS()
      .pipe(takeUntil(this.stopSubscription$))
      .subscribe((resp: Respuesta) => {
        this.listEPS = resp.data.eps;
        console.log(this.listEPS,'eps')
      })  */
  }


  tipoAgente(): string {
    let resultado = null;
    // eslint-disable-next-line angular/definedundefined
    if (this.listTipoRelacionesEntrePersonas !== undefined) {
      this.listTipoRelacionesEntrePersonas.forEach(element => {
        if (element.id === this.form.relacionEntrePersona.tipoRelacionId) {
          resultado = element.tipo;
        }
      });
    }

    console.log(this.form.relacionEntrePersona.tipoRelacionId, 'id')
    return resultado;
  }

}
