<mat-card>
  <form #formInvoice="ngForm">
    <div mat-dialog-content style="overflow-x: hidden;">
      <div fxFlex="100%">

        <div style="margin-top: 1%;" fxLayoutGap="20px">
          <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">

            <mat-form-field fxFlex appearance="outline">
              <mat-label for="socioPacciolo"> Nombre</mat-label>
              <mat-select name="nombre">
                <mat-option>
                  <ngx-mat-select-search noEntriesFoundLabel="No hay coincidencias" #search
                    placeholderlabel="Buscar...">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option >sdsoption>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
              <mat-label for="salarioBasico"> Salario Básico</mat-label>
              <input matInput type="text" id="salarioBasico" name="salarioBasico" required>
            </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
              <mat-label for="fechaInicio">Fecha Inicio</mat-label>
              <input matInput id="fechaInicio" name="fechaInicio" [matDatepicker]="pickerInicio" required>
              <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
              <mat-datepicker #pickerInicio startView='month'></mat-datepicker>
            </mat-form-field>

          </div>

          <div style="margin-top: 1%;" fxLayoutGap="20px">

            <mat-form-field fxFlex appearance="outline">
              <mat-label for="eps"> EPS</mat-label>
              <input matInput type="text" id="eps" name="eps" disabled required>
            </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
              <mat-label for="arl"> ARL</mat-label>
              <input matInput type="text" id="arl" name="arl" disabled required>
            </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
              <mat-label for="afp"> AFP</mat-label>
              <input matInput type="text" id="afp" name="afp" disabled required>
            </mat-form-field>

          </div>

          <div style="margin-top: 1%;" fxLayoutGap="20px">

            <mat-form-field fxFlex appearance="outline">
              <mat-label for="cajaCompensacion"> Caja Compensación</mat-label>
              <input matInput type="text" id="cajaCompensacion" name="cajaCompensacion" disabled required>
            </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
              <mat-label for="fondosCesantias"> Fondos Cesantías</mat-label>
              <input matInput type="text" id="fondosCesantias" name="fondosCesantias" disabled required>
            </mat-form-field>

          </div>

        </div>

      </div>
    </div>
  </form>

  <mat-card-actions>
    <button mat-raised-button color="primary">
      Guardar
    </button>
  </mat-card-actions>
</mat-card>