import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Respuesta } from '@app/_interfaces/respuesta';
import { ConsToken } from '@app/_services/constToken';
import { HttpClientService } from '@app/_services/http_client_service/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class RrhhService {

  constructor(
    private http: HttpClientService
  ) { }

  getInfoConfigCreateUpdatePerson2(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/common/getInfoConfigCreateUpdatePerson/${socioPacciolo}`);
  }

  

}
