import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Toast } from '@app/_services/toast';

// tslint:disable-next-line: import-blacklist
import { Subscription } from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';
import { Respuesta } from '../_interfaces/respuesta';
import { Session } from '../_class/session';
import { environment } from '../../environments/environment';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  form = {
    username: null,
    password: null
  };

  envio = false;

  private sbLogin: Subscription;
  constructor(
    public router: Router,
    // tslint:disable-next-line:variable-name
    private _servicio: AuthenticationService,
    // tslint:disable-next-line:variable-name
    private _toast: Toast
  ) {
    this.envio = true;
    if (Session.logueado()) {
      this.router.navigate(['Mstr/🏠']);
    } else {
      this.router.navigate(['login']);
    }
    this.envio = false;
  }

  login(): void {
    this.envio = true;
    this.sbLogin = this._servicio.login2(this.form).subscribe((resp: Respuesta) => {
      this.envio = false;

      if (resp.code === 200) {
        this._toast.open(`Te damos la bienvenida ${resp.data.usuario.nombre}`);
        Session.guardar(resp.data);
        environment.socioPacciolo = resp.data.usuario.empresa[0].id;
        environment.persona = resp.data.usuario.id;
        this.router.navigate(['Mstr/🏠']);
      }
    },
      () => {
        this.envio = false;
        this._toast.open('Credenciales incorrectas');
      }
    );
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    if (this.sbLogin) {
      this.sbLogin.unsubscribe();
    }
  }
}
