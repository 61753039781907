/* tslint:disable:variable-name */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Toast } from '@app/_services/toast';
// import { Session } from '@app/_class/session';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-spash',
  templateUrl: './spash.component.html',
  styleUrls: ['./spash.component.css']
})
export class SpashComponent implements OnInit {
  constructor(private router: Router, private _toast: Toast) {}

  ngOnInit(): void {

    this.router.navigate(['login']);

    // if (Session.logueado()) {

    //   const token: any = Session.getItem('access_token');
      
    //   if (Date.now() / 1000 > token.exp) {
    //     Session.cerrar();
    //     this.router.navigate(['login']);
    //     this._toast.open('Sesión vencida');
    //   } else {
    //     this.router.navigate(['Mstr']);
    //   }
    // } else {
    //   this.router.navigate(['login']);
    // }
  }
}
