import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserIdleModule } from 'angular-user-idle';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxMaskModule } from 'ngx-mask';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpashComponent } from './spash/spash.component';
import { ServiceModule } from './_services/service.module';
import { ProviderModule } from './provider.module';
import { LoginComponent } from './login/login.component';
import { RefreshTokenInterceptor } from './Clases/interceptor_refresh_token/refresh-token-interceptor';
import { AuthGuard } from './_services/_guard/auth/auth.guard';
import { MatPaginatorIntlCro } from '../app/costumClass';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSortModule } from '@angular/material/sort';
import { MatNativeDateModule } from '@angular/material/core';
import { FormTableHarvestDetailComponent } from './redAmbiental/components/dummies/form-table-harvest-detail/form-table-harvest-detail.component';
import { ResolucionFacturacionComponent } from './facturacion/components/smart/resolucion-facturacion/resolucion-facturacion.component';
import { ResolucionTableComponent } from './facturacion/components/dummies/resolucion-table/resolucion-table.component';
import { LayoutResolucionFacturacionComponent } from './facturacion/components/dummies/layout-resolucion-facturacion/layout-resolucion-facturacion.component';
import { ListInvoiceAnalysisComponent } from './facturacion/components/dummies/list-invoice-analysis/list-invoice-analysis.component';
import { LayoutInvoiceAnalysisComponent } from './facturacion/components/smart/layout-invoice-analysis/layout-invoice-analysis.component';
import { InvoiceForClientComponent } from './facturacion/components/smart/invoice-for-client/invoice-for-client.component';
import { FormSaldosInicialesComponent } from './caracteristicasPacciolo/components/dummies/form-saldos-iniciales/form-saldos-iniciales.component';
import { LayoutSaldosInicialesComponent } from './caracteristicasPacciolo/components/smart/layout-saldos-iniciales/layout-saldos-iniciales.component';
import { DatosEmpleadosComponent } from './rrhh/components/smart/datos-empleados/datos-empleados.component';
import { CrearNominaComponent } from './rrhh/components/smart/crear-nomina/crear-nomina.component';
import { OpNominaComponent } from './rrhh/components/dummies/op-nomina/op-nomina.component';
import { EmpleadoListComponent } from './rrhh/components/dummies/empleado-list/empleado-list.component';
import { ReporteKgComponent } from './redAmbiental/components/smart/reporte-kg/reporte-kg.component';
import { ModalInvoiceBulkComponent } from './facturacion/components/dummies/modal-invoice-bulk/modal-invoice-bulk.component';
import { ListSoloNombreComponent } from './crm/components/dummies/list-solo-nombre/list-solo-nombre.component';
import { FormSoloNombreComponent } from './crm/components/dummies/form-solo-nombre/form-solo-nombre.component';
import { LayoutSoloNombreComponent } from './crm/components/smart/layout-solo-nombre/layout-solo-nombre.component';



registerLocaleData(es);
@NgModule({
  declarations: [
    AppComponent,
    SpashComponent,
    LoginComponent,
    FormTableHarvestDetailComponent,
    ResolucionFacturacionComponent,
    ResolucionTableComponent,
    LayoutResolucionFacturacionComponent,
    ListInvoiceAnalysisComponent,
    LayoutInvoiceAnalysisComponent,
    InvoiceForClientComponent,
    FormSaldosInicialesComponent,
    LayoutSaldosInicialesComponent,
    DatosEmpleadosComponent,
    CrearNominaComponent,
    OpNominaComponent,
    EmpleadoListComponent,
    ReporteKgComponent,
    ModalInvoiceBulkComponent,
    ListSoloNombreComponent,
    FormSoloNombreComponent,
    LayoutSoloNombreComponent,
    
  ],
  imports: [
    AppRoutingModule,
    ServiceModule,
    HttpClientModule,
    ProviderModule,
    BrowserModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    LayoutModule,
    FlexLayoutModule,
    UserIdleModule.forRoot({ idle: 60, timeout: 300, ping: 60 }),
    NgxMaskModule.forRoot(),
    LoadingBarModule,
    MatTableModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSortModule,
    MatNativeDateModule,
  ],
  providers: [
    { 
      provide: LOCALE_ID,
      useValue: 'es-CO'
    },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
