import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { environment } from 'environments/environment';
import { DireccionType } from '@app/persona/types/direccion.type'
import { DireccionService } from '@app/persona/services/direccion/direccion.service';

import { MatTableDataSource } from '@angular/material/table';
import { Respuesta } from '@app/_interfaces/respuesta';

@Component({
  selector: 'app-layout-solo-nombre',
  templateUrl: './layout-solo-nombre.component.html',
  styleUrls: ['./layout-solo-nombre.component.scss']
})
export class LayoutSoloNombreComponent implements OnInit {

  direccionList: DireccionType []

  displayedColumns = ['nombre', 'accion']

  dataSource = new MatTableDataSource<DireccionType>();
  socioPacciolo = environment.socioPacciolo;
  private stopSubscription$ = new Subject<void>()

  constructor(
    private direccionService: DireccionService
  ) { }

  ngOnInit(): void {
    this.getDirecciones()
  }


  private getDirecciones():void {
    this.direccionService.getAddressTypeOneTwoForSelect(this.socioPacciolo)
    .pipe(takeUntil(this.stopSubscription$))
    .subscribe((resp: Respuesta) => {
      if (resp.code === 200) {
        this.direccionList = resp.data
      }
    })
  }


}
