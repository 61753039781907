import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FacturaType } from '@app/facturacion/types/factura.types';
import { MatTableDataSource } from '@angular/material/table';
import { RecoleccionType } from '@app/redAmbiental/types/recoleccion.type';
import { FacturacionService } from '@app/facturacion/services/facturacion.service';
import { DireccionType } from '@app/persona/types/direccion.type';
import { environment } from 'environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import  DetalleRecoleccionType  from '@app/redAmbiental/types/detalleRecoleccion.type';
import { RedAmbientalService } from '@app/redAmbiental/services/red_ambiental_service/red-ambiental.service';


@Component({
  selector: 'app-invoice-for-client',
  templateUrl: './invoice-for-client.component.html',
  styleUrls: ['./invoice-for-client.component.scss']
})
export class InvoiceForClientComponent implements OnInit, OnChanges {

  historial: Array<any> = [];
  invoice:any = []
  addressList: DireccionType[] = [];
  datos: FacturaType [] = []
  a:any  = []
  b:any  = []
  

  @Input() invoiceList: FacturaType[] = [];
  @Input() search: string
  @Input() invoiceAnalysis: DetalleRecoleccionType[];
  @Input() recolect: DetalleRecoleccionType[] = [];
  @Input() displayedColumns: string[];

  dataSource = new MatTableDataSource<DetalleRecoleccionType>();
  @ViewChild('paginador') paginador: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  socioPacciolo = environment.socioPacciolo;

  constructor(
    private facturacionService: FacturacionService,
    private redAmbientalService: RedAmbientalService
  ) { }


  


  public monthValue():void {
    
  }

  

  public getRecolect2():void {
    this.redAmbientalService.getHarvestBylastMonth(29,12).subscribe(
      res => {
        this.recolect = res.data
        this.dataSource.paginator = this.paginador;
        
      },
      err => console.error(err)
    ) 
  }
/* 
  total(): number {
    let total = 0;
    this.historial.forEach(element => {
      total += element.total_recolecion;
    });
    return total;
  } */

  ngOnInit(): void {
    console.log(this.dataSource, 'invoice')
    console.log(this.recolect,'data2')
    console.log(this.invoiceList,'invoice analisis')
    this.getRecolect2()

  }

  

 


  ngOnChanges(changes: SimpleChanges): void {
    this.changeInvoice(changes);
    this.changeInvoiceList(changes)
    this.changeSearchClient(changes)
    
  }

  /**
   * @description
   * Función que observa los cambios de la propiedad Invoice
   * @param change Contiene un objeto de tipo SimpĺeChanges
   */
  private changeInvoice(change: SimpleChanges): void {

    if (change.recolect && !change.recolect.firstChange) {
      this.recolect = change.recolect.currentValue;
      this.dataSource.data = change.recolect.currentValue;
    }
    
  }

  private changeInvoiceList(change: SimpleChanges): void {

    if (change.recolect && !change.recolect.firstChange) {
      this.recolect = change.recolect.currentValue;
      this.dataSource.data = change.recolect.currentValue;
      console.log(this.dataSource,'datasource')
      setTimeout(() => {
        this.dataSource.paginator = this.paginador;
        this.dataSource.sort = this.sort;
      }, 300);
    }
  }

    /**
   * @description
   * Función que observa los cambios de la propiedad search
   * @param change De tipo SimpleChanges
   */
     private changeSearchClient(change: SimpleChanges): void {
      if (change.search && !change.search.firstChange) {
        this.search = change.search.currentValue;
        this.applyFilter();
      }
    }

  /**
   * @description
   * Función que filtra las facturas
   */
  private applyFilter(): void {
    this.dataSource.filter = this.search.trim().toLowerCase();
  }

}
