import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';

import { PersonaService } from '@app/persona/services/persona/persona.service';
import { RedAmbientalService } from '@app/redAmbiental/services/red_ambiental_service/red-ambiental.service';
import { FacturacionService } from '@app/facturacion/services/facturacion.service';
import { Swal } from '@app/_services/swal';

import { Respuesta } from '@app/_interfaces/respuesta';
import { TerceroType } from '@app/persona/types/tercero.type';
import { FacturaType } from '@app/facturacion/types/factura.types';
import { DireccionType } from '@app/persona/types/direccion.type';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-layout-invoice-analysis',
  templateUrl: './layout-invoice-analysis.component.html',
  styleUrls: ['./layout-invoice-analysis.component.scss']
})
export class LayoutInvoiceAnalysisComponent implements OnInit {

  clientes: TerceroType[] = [];
  socioPacciolo = environment.socioPacciolo;
  cliente: any = { id: null, interval: null };

  facturas$: Observable<FacturaType[]>;
  direcciones$: Observable<DireccionType[]>;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private personaService: PersonaService,
    private facturacionService: FacturacionService,
    private redAmbientalService: RedAmbientalService
  ) { }

  /**
   * @description
   * Función que ejecuta las funciones
   * onGetInvoicesByLastMonth()
   * onGetHarvestBylastMonth()
   */
  onExecuteFunctions(): void {
    if (this.cliente.interval) {
      this.onGetInvoicesByLastMonth();
      this.onGetHarvestBylastMonth();
    } else {
      Swal.alerta('La Fecha Inicio debe ser menor a la Fecha Fin');
    }
  }

  /**
   * @description
   * Función que busca las facturas facturas pasadas en base a un intervalo de meses
   */
  onGetInvoicesByLastMonth(): void {
    
    
    this.facturacionService.getInvoicesByLastMonth(this.cliente.id, this.socioPacciolo, this.cliente.interval)
      .pipe(takeUntil(this.unsubscribe$)).subscribe((resp: Respuesta) => {
        this.facturas$ = of(resp.data);
      });
  }

  /**
   * @description
   * Función que busca las recolecciones de los meses pasados en base al interval
   */
  onGetHarvestBylastMonth(): void {
    this.redAmbientalService.getHarvestBylastMonth(this.cliente.id, this.cliente.interval)
      .pipe(takeUntil(this.unsubscribe$)).subscribe((resp: Respuesta) => {
        this.direcciones$ = of(resp.data.direcciones);
      });
  }

  ngOnInit(): void {
    this.getClient();
  }

  ngOnDestroy(): void {
    this.stopUnsubscribe();
  }

  /**
   * @description
   * Función que busca todos los clientes
   */
  private getClient(): void {
    this.personaService.getPersonByTypeRelation([6], environment.socioPacciolo).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: Respuesta) => {
      if (resp.code === 200) {
        this.clientes = resp.data;
      } else {
        Swal.error(resp.mensaje);
      }
    });
  }

  /**
   * @description
   * Función que desuscribe todos las suscripciones
   */
  private stopUnsubscribe(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
