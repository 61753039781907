import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

// Interfaces
import { TerceroType } from '@app/persona/types/tercero.type';
import { Grupos } from '@app/_interfaces/inventario/grupos';
import { ProductoType } from '@app/_interfaces/inventario/productos.type';
import { Clausula } from '@app/_interfaces/gestion_contratos/clausula-interface';
import { DireccionType } from '@app/persona/types/direccion.type';
import { ContratoType } from '@app/_interfaces/gestion_contratos/contrato.type';
import * as moment from 'moment';

import { Respuesta } from '../../_interfaces/respuesta';
// Services
import { HttpClientService } from '../http_client_service/http-client.service';
import { ConsToken } from '../constToken';

@Injectable({
  providedIn: 'root'
})
export class ContratosService {
  constructor(private http: HttpClientService, private http2: HttpClient) {
    moment.locale('es');
  }

  clientesSocioPaccioloList(socioPacciolo: number): Observable<TerceroType> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/clientesList/' + socioPacciolo).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  gruposList(socioPacciolo: number): Observable<Grupos> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/grupos/' + socioPacciolo).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  productoContractual(idGrupo: number): Observable<ProductoType> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/productosDeGrupo/' + idGrupo).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  productosDeProductos(idProd: number): Observable<ProductoType> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/productosDeProductos/' + idProd).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  clausulasList(socioPacciolo: number): Observable<Clausula> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/clausulasList/' + socioPacciolo).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  sucursalesByIdList(idProd: number): Observable<DireccionType> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/sucursalesList/' + idProd).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  gerentesList(idEmpresa: number): Observable<TerceroType> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/' + idEmpresa).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  contratosList(data: ContratoType): Observable<ContratoType> {

    return this.http.post(ConsToken.urlMicroServiceContratos + '/contratos/contratoFilter', data).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data.map(contratos => {
          const con = <ContratoType>contratos;
          con.fechaInicio = moment(contratos.fechaInicio).format('LL');
          con.fechaFin = moment(contratos.fechaFin).format('LL');
          con.clausulas = JSON.parse(contratos.clausulas);
          return con;
        });
      })
    );
  }

  contratosGet(contratoId: number): Observable<ContratoType> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/' + contratoId).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  contratoHasProductosGetById(contratoId: number): Observable<ProductoType> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/contratoHasProductosGetById/' + contratoId).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  getContratos(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceContratos + '/contratos/getContratos')
  }

  clausulasEdit(data: Clausula): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServiceContratos + '/contratos/clausulasEdit/', data);
  }

  contratoHasProductoDelete(id: number): Observable<Respuesta> {
    return this.http.delete(ConsToken.urlMicroServiceContratos + '/contratos/contratoHasProductosRemove/' + id);
  }

  clausulasSave(data: Clausula): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceContratos + '/contratos/clausulasSave', data);
  }

  contratosSave(data: ContratoType): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceContratos + '/contratos', data);
  }

  contratosUpdate(data: ContratoType): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServiceContratos + '/contratos', data);
  }

  listar(): any {
    return this.http.get(ConsToken.urlNode + '/contrato/contratos');
  }

  listar_grupos(): any {
    return this.http.get(ConsToken.urlNode + '/contrato/grupos');
  }

  filtro_contratos(data: any): any {
    return this.http.post(ConsToken.urlNode + '/contrato/filtros', data);
  }

  listar_productos_de_grupo(id: number): any {
    return this.http.get(ConsToken.urlNode + '/contrato/productos_de_grupo/' + id);
  }

  listar_productos_de_productos(id: number): any {
    return this.http.get(ConsToken.urlNode + '/contrato/productos_de_productos/' + id);
  }

  listar_clausulas(): any {
    return this.http.get(ConsToken.urlNode + '/clausulas/listar_clausulas');
  }

  guardar_clausulas(data: any): any {
    return this.http.post(ConsToken.urlNode + '/clausulas/clausulas', data);
  }

  modificar_gerentes(data: any): any {
    return this.http.put(ConsToken.urlNode + '/contrato/modificarGerente', data);
  }

  modificar_estado_contrato(data: any): any {
    return this.http.put(ConsToken.urlNode + '/contrato/modificar_estado_contrato', data);
  }

  modificar_modelo(data: any): any {
    return this.http.put(ConsToken.urlNode + '/clausulas/clausulas', data);
  }

  modificar_contrato(data: any): any {
    return this.http.put(ConsToken.urlNode + '/contrato/contrato', data);
  }

  obtenerConsecutivo(): any {
    return this.http.get(ConsToken.urlNode + '/contrato/obtenerConsecutivo');
  }

  get_contrato(id: number): any {
    return this.http.get(ConsToken.urlNode + '/contrato/contratos/' + id);
  }

  guardarClauDeCont(clau: any): any {
    // el json de mi componente crear pais va a llegar acá con este nombre
    return this.http.post(ConsToken.url + '/guardar_clausulas_de_contratos', clau);
  }

  pdfContratos(): any {
    return this.http.get(ConsToken.urlNode + '/contrato/pdfContratos/');
  }

  redAmbientalCliente(): any {
    return this.http2.get('http://localhost:4001/facturas');
  }

  readFile(): any {
    return this.http2.get('assets/img.txt/', { responseType: 'text' });
  }

  /**
   * @description
   * Función que obtiene los contratos activos asociados a una dirección
   * @param direccionId Contiene el ID de una dirección
   */
  getContractsActivesByAddressForSelect(direccionId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceContratos + `/contratos/getContractsActivesByAddressForSelect/${direccionId}`);
  }

  // listarTipoPago() {
  //   return this.http.get(ConsToken.url + '/tipoPagoList');
  // }

  // listarContratos() {
  //   return this.http.get(ConsToken.url + '/listar_contratos');
  // }

  // getContrato(id) {
  //   return this.http.get(ConsToken.url + '/getContratobyId/' + id);
  // }

  // editar(contrato) {
  //   return this.http.put(ConsToken.url + '/editarContrato', contrato);
  // }

  // guardarClausulas(clausulas) {
  //   return this.http.post(ConsToken.url + '/guardar_clausulas', clausulas);
  // }

  // listarClausulas() {
  //   return this.http.get(ConsToken.url + '/listar_clausulas');
  // }
}
