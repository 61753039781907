import { Component, OnInit } from '@angular/core';
import { RrhhService } from '@app/rrhh/services/rrhh.service';
import { DireccionService } from '@app/persona/services/direccion/direccion.service';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Respuesta } from '@app/_interfaces/respuesta';
import { DireccionType } from '@app/persona/types/direccion.type';
import { EpsType } from '@app/rrhh/types/eps.type';
import { MatTableDataSource } from '@angular/material/table';
import { ContratosService } from '@app/_services/contratos_service/contratos.service';
import { ContratoType } from '@app/_interfaces/gestion_contratos/contrato.type';
import { ContratosEmpleados } from '@app/_interfaces/gestion_contratos/contratos-empleados';

@Component({
  selector: 'app-crear-nomina',
  templateUrl: './crear-nomina.component.html',
  styleUrls: ['./crear-nomina.component.scss']
})
export class CrearNominaComponent implements OnInit {

  addressList: DireccionType;
  epsList: EpsType
  contratoList: ContratosEmpleados
  data:any = []

  dataSource = new MatTableDataSource<ContratosEmpleados>();
  socioPacciolo = environment.socioPacciolo;
  private stopSubscription$ = new Subject<void>()

  constructor(
    private rrhhService: RrhhService,
    private direccionService: DireccionService,
    private contratoService: ContratosService

  ) { }

  ngOnInit(): void {
    this.getContratos();
    this.getAddress();
  }                    
  
  private getAddress():void {
    this.direccionService.getAddressTypeOneTwoForSelect(this.socioPacciolo)
    .pipe(takeUntil(this.stopSubscription$))
    .subscribe((resp: Respuesta) => {
      if (resp.code === 200) {
        this.addressList = resp.data
      }
    })
  }

  

  private getEps():void {
    /* this.rrhhService.getEPS()
    .pipe(takeUntil(this.stopSubscription$))
    .subscribe((resp: Respuesta) => {
      if (resp.code === 200){
        this.epsList = resp.data
      }
      console.log(this.epsList, 'epslist')
    }) */

  }

  private getContratos():void {
    /* this.contratoService.getContratos()
    .pipe(takeUntil(this.stopSubscription$))
    .subscribe((resp: Respuesta) => {
      if(resp.code === 200) {
        this.contratoList = resp.data
      }
      console.log(this.contratoList,'contrato')
    }) */
  }


}
