<div *ngIf="data.tipo === 1">
	<h1 mat-dialog-title> Crear Interacción a una PQRS </h1>
	<div mat-dialog-content>
		<form #formIntera="ngForm">
			<mat-form-field appearance="outline">
				<mat-label for="descripcion">Describa la interacción</mat-label>
				<textarea matInput mat-autosize name="descripcion" id="descripcion" [(ngModel)]="interaccion.descripcion"
					required></textarea>
			</mat-form-field>

			<mat-form-field appearance="outline">
				<mat-label for="proximaInteraccion">Próxima Interacción</mat-label>
				<textarea matInput mat-autosize name="proximaInteraccion" id="proximaInteraccion"
					[(ngModel)]="interaccion.proximaInteraccion"></textarea>
			</mat-form-field>

			<mat-form-field appearance="outline" *ngIf="interaccion.proximaInteraccion">
				<mat-label for="fechaProximaInteraccion">Fecha proxima Interacción</mat-label>
				<input matInput [matDatepicker]="picker2" id="fechaProximaInteraccion" name="fechaProximaInteraccion"
					[(ngModel)]="interaccion.fechaProximaInteraccion" disabled>

				<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
				<mat-datepicker #picker2 disabled="false"></mat-datepicker>
			</mat-form-field>
		</form>
	</div>

	<div mat-dialog-actions>
		<button mat-button [disabled]="!formIntera.form.valid" (click)="onSavePqrsInteraction()">Guardar</button>
		<button mat-button [mat-dialog-close]="respuesta" id="closeModal">Cerrar</button>
	</div>
</div>

<div *ngIf="data.tipo === 2">
	<h1 mat-dialog-title> Lista de Interacciones de una PQRS </h1>

	<h5 *ngIf="dataSource.data.length === 0" fxLayoutAlign="center center">No se encontrarón interacciones para esta
		pqrs</h5>

	<div *ngIf="dataSource.data.length > 0">
		<div mat-dialog-content>

			<h5>Próxima interacción:
				{{ data.pqrs.proximaInteraccion ? data.pqrs.proximaInteraccion + '.' : 'No hay proxima interacción' }}
			</h5>

			<table class="width-100" mat-table [dataSource]="dataSource">
				<ng-container matColumnDef="pqrs">
					<th mat-header-cell *matHeaderCellDef> PQRS </th>
					<td mat-cell *matCellDef="let element"> {{ element.descripcion }} </td>
				</ng-container>

				<ng-container matColumnDef="fecha">
					<th mat-header-cell *matHeaderCellDef> Fecha Proxima Interacción </th>
					<td mat-cell *matCellDef="let element" [innerHTML]="_ss.formatDate(element.fecha)"></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
		</div>
	</div>
	<mat-paginator [pageSizeOptions]="_ss.paginador" showFirstLastButtons></mat-paginator>
</div>
