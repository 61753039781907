<mat-card>
<form #formInvoice="ngForm">
  <div mat-dialog-content style="overflow-x: hidden;">
    <div fxFlex="100%">

      <div style="margin-top: 1%;" fxLayout="row wrap" fxLayoutGap="20px">
        <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">

          <mat-form-field fxFlex appearance="outline">
            <mat-label for="socioPacciolo"> Socio Pasiolo</mat-label>
            <input matInput type="text" id="socioPacciolo" [(ngModel)]="resolution.socioPacciolo" name="nombre" disabled required>
          </mat-form-field>

          <mat-form-field fxFlex appearance="outline">
            <mat-label for="personaId"> PersonaId</mat-label>
            <input matInput type="text" id="personaId" name="personaId" [(ngModel)]="resolution.personaId" required>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">

          <mat-form-field fxFlex appearance="outline">
            <mat-label for="prefijo"> Prefijo</mat-label>
            <input matInput type="text" id="prefijo" name="prefijo" [(ngModel)]="resolution.prefijo" required>
          </mat-form-field>

          <mat-form-field fxFlex appearance="outline">
            <mat-label for="numeroAutorizacion"> Numero de Autorización</mat-label>
            <input matInput type="text" id="numeroAutorizacion" name="numeroAutorizacion" [(ngModel)]="resolution.numeroAutorizacion" required>
          </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">

          <mat-form-field fxFlex appearance="outline">
            <mat-label for="numeroInicial"> Numero Inicial
            </mat-label>
            <input matInput type="text" id="numeroInicial" name="numeroInicial" [(ngModel)]="resolution.numeroInicial" required>
          </mat-form-field>

          <mat-form-field fxFlex appearance="outline">
            <mat-label for="numeroFinal"> Numero Final
            </mat-label>
            <input matInput type="text" id="numeroFinal" name="numeroFinal" [(ngModel)]="resolution.numeroFinal" required>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">

          <mat-form-field appearance="outline" fxFlex>
            <mat-label for="fechaInicio">Fecha Inicio</mat-label>
            <input matInput id="fechaInicio" name="fechaInicio" [(ngModel)]="resolution.fechaInicio" [matDatepicker]="pickerInicio"
               required>
            <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
            <mat-datepicker #pickerInicio startView='month'></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label disabled for="fechaFin">Fecha Fin</mat-label>
            <input matInput id="fechaFin" name="fechaFin" [(ngModel)]="resolution.fechaFin" [matDatepicker]="pickerFin"
               required>
            <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
            <mat-datepicker #pickerFin startView='month'></mat-datepicker>
          </mat-form-field>



          <mat-form-field fxFlex appearance="outline">
            <mat-label for="estado"> Estado</mat-label>
            <input matInput type="text" id="estado" name="estado" [(ngModel)]="resolution.estado" required>
          </mat-form-field>
        </div>

      </div>

    </div>
  </div>
</form>

<mat-card-actions>
  <button mat-raised-button color="primary" (click)="onSaveResolution()">
    Guardar
  </button>
</mat-card-actions>
</mat-card>
