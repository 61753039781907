import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { HttpClientService } from '@app/_services/http_client_service/http-client.service';
import { ConsToken } from '@app/_services/constToken';

import { Respuesta } from '@app/_interfaces/respuesta';
import { ProductoType } from '@app/_interfaces/inventario/productos.type';

@Injectable({
  providedIn: 'root'
})
export class RedAmbientalService {

  constructor(
    private http: HttpClientService
  ) {
    moment.locale('es');
  }

  // tslint:disable-next-line: variable-name
  manifiestos(rhps_id: number): Observable<any> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + '/recoleccion/manifiesto/' + rhps_id);
  }

  sucursalesFilter(data: any): Observable<any> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + '/rutas/sucursalesFilter', data).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  sucursalesByContratos(data: any): Observable<any> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + '/rutas/sucursalesByContratos', data).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data.filter((client) => client.nombre !== null && client.contratos.length > 0);
      })
    );
  }

  conductoresList(id: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + '/rutas/conductoresList/' + id).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  getRecoleccionResiduos(direccionId: number): any {
    return this.http.get(ConsToken.urlNode + `/rhps/` + direccionId)
  }

  vehiculosList(socioPacciolo: number): Observable<ProductoType[]> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + '/rutas/vehiculosList/' + socioPacciolo).pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data;
      })
    );
  }

  rhpsGet(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + '/rhps/rhpsGet')
      .pipe(
        map(element => {
          const respuesta = <Respuesta>element;
          return respuesta.data.map(recolecciones => {
            const respuesta2 = recolecciones;
            respuesta2.fechaRecoleccion = moment(recolecciones.fechaRecoleccion).format('LL');
            respuesta2.fechaEstimada = moment(recolecciones.fechaEstimada).format('LL');
            return respuesta2;
          });
        })
      );
  }

  recoleccionGet(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + '/recoleccion/recoleccionesGet', data)
      .pipe(map(element => {
        const res = <Respuesta>element;
        return res.data;
      }));
  }

  recoleccionUpdate(data: any): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServiceRedAmbiental + '/recoleccion', data);
  }

  // ************************************* RHPS ******************************** //

  rhpsList(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + '/rhps').pipe(
      map(element => {
        const respuesta = <Respuesta>element;
        return respuesta.data.map(
          rhps => {
            const convert = <any>rhps;
            convert.jsonDirecciones = JSON.parse(rhps.jsonDirecciones);
            convert.fechaRecoleccion = moment(rhps.fechaRecoleccion).format('LL');
            return convert;
          }
        );
      })
    );
  }

  validateRhps(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServiceRedAmbiental + '/rutas/validarRhps', data).pipe(
      map(element => {
        const rhps = <Respuesta>element;
        return rhps.data;
      })
    );
  }

  // ********************************************************************** //
  // *******************************Recolecciones *************************************** //

  /**
   * @description
   * Función que busca las recolecciones de los meses pasados en base al interval
   * @param personaId Contiene el ID de una persona
   * @param interval Contiene el número de meses hacia atras que queremos evaluar
   */
  getHarvestBylastMonth(personaId: number, interval: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getHarvestBylastMonth/${personaId}/${interval}`);
  }

  getHarvestByPersonIncludingBranchOffices(personaId: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + `/recoleccion/getHarvestByPersonIncludingBranchOffices/${personaId}`);
  }

  // *******************************Fin Recolecciones *************************************** //
  
  // ******************************* Dinero Recibido Conductores*************************************** //

  dineroRecibidoList(): Observable<any> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + '/recoleccion/dinerosRecibidos/');
  }
  // ******************************* Dinero Recibido Conductores*************************************** //  
  crear_rutas(data: any): any {
    return this.http.post(ConsToken.urlNode + '/rutas/rutas', data);
  }

  listar_rutas(): any {
    return this.http.get(ConsToken.urlNode + '/rutas/rutas');
  }

  listar_clientes_de_red_ambiental(): any {
    return this.http.get(ConsToken.urlNode + '/rutas/clientes_red_ambiental');
  }

  editarRutas(data: any): any {
    return this.http.put(ConsToken.urlNode + '/rutas/asignarClientesArutas', data);
  }

  eliminar_ruta(id: number): any {
    return this.http.delete(ConsToken.urlNode + '/rutas/eliminar_ruta/' + id);
  }

  /* -------------------------------Rutas--------------------------- */

  /*--------------------------------RHPS----------------------------- */
  mostrarVehiculos(): any {
    return this.http.get(ConsToken.urlNode + '/rhps/activos');
  }

  guardarRhps(data: any): any {
    return this.http.post(ConsToken.urlNode + '/rhps/rhps', data);
  }

  residuosRecolectados(idPersona: number): any {
    return this.http.get(ConsToken.urlNode + '/rhps/' + idPersona);
  }

  get_conductores(): any {
    return this.http.get(ConsToken.urlNode + '/rhps/conductores');
  }

  listar_rhps(): any {
    return this.http.get(ConsToken.urlNode + '/rhps/listar_rhps');
  }

  guardar_residuos(data: any): any {
    return this.http.post(ConsToken.urlNode + '/rhps/guardar_residuos', data);
  }

  // tslint:disable-next-line:variable-name
  get_manifiesto(idRhps: number): any {
    return this.http.get(ConsToken.urlNode + '/rhps/manifiesto/' + idRhps);
  }

  getRhps(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServiceRedAmbiental + '/rhps/getRhps');
  }
}
