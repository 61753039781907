import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';

import { PqrsService } from '@app/pqrs/services/pqrs.service';
import { PersonaService } from '@app/persona/services/persona/persona.service';
import { SharedService } from '@app/_services';
import { Swal } from '@app/_services/swal';

import { Respuesta } from '@app/_interfaces/respuesta';
import { PqrsInteraccionesType } from '@app/pqrs/types/pqrsInteracciones.type';
import { environment } from 'environments/environment';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-interacciones-pqrs',
  templateUrl: './interacciones-pqrs.component.html',
  styleUrls: ['./interacciones-pqrs.component.css']
})
export class InteraccionesPqrsComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  interaccion: PqrsInteraccionesType = {
    pqrsId: null,
    descripcion: null,
    proximaInteraccion: null,
    fechaProximaInteraccion: null,
    pqrs: { id: null, tercero: null, socioPacciolo: null }
  };

  dataSource = new MatTableDataSource<PqrsInteraccionesType>();
  displayedColumns = ['pqrs', 'fecha'];

  respuesta: Respuesta;
  listaEmpleados = [];

  private subGetAllEmployeesForSelect: Subscription;
  private subSavePqrsInteraction: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pqrsService: PqrsService,
    private personaService: PersonaService,
    public _ss: SharedService
  ) { }

  onSavePqrsInteraction(): void {

    this.interaccion.fechaProximaInteraccion = this.interaccion.proximaInteraccion ?
      this._ss.formatDate(this.interaccion.fechaProximaInteraccion) : null;

    this.interaccion.pqrsId = this.data.pqrs.id;

    if (this.interaccion.descripcion) {

      if (this.interaccion.proximaInteraccion && !this.interaccion.fechaProximaInteraccion) {
        Swal.alerta('Por favor si agrega una interacción asigne una fecha..!');
        return;
      }

      if (this.interaccion.proximaInteraccion && !(moment(this.interaccion.fechaProximaInteraccion).isAfter(moment()))) {
        Swal.alerta('La fecha de la proxima interacción debe ser mayor o igual a la fecha actual..!');
        return;
      }

      this.subSavePqrsInteraction = this.pqrsService.savePqrsInteraction(this.interaccion).subscribe((resp: Respuesta) => {

        if (resp.code === 201) {

          this.respuesta = resp;

          setTimeout(() => {
            (<HTMLInputElement>document.getElementById('closeModal')).click();
          }, 100);

          Swal.correcto(resp.mensaje);

        } else if (resp.code === 204) {
          Swal.alerta(resp.mensaje);
        } else {
          Swal.error(resp.mensaje);
        }
      });
    } else {
      Swal.alerta('Por favor agregue una descripción de la interacción...!');
    }
  }

  ngOnInit(): void {
    if (this.data.tipo === 1) {
      this.interaccion.descripcion = this.data.pqrs.proximaInteraccion ? this.data.pqrs.proximaInteraccion : null;
      this.interaccion.pqrs.id = this.data.pqrs.id;
      this.interaccion.pqrs.tercero = environment.persona;
      this.interaccion.pqrs.socioPacciolo = environment.socioPacciolo;
    } else if (this.data.tipo === 2) {
      this.dataSource.data = this.data.pqrs.interacciones;
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      }, 50);
    } else if (this.data.tipo === 3) {
      this.getAllEmployeesForSelect();
    }
  }

  ngOnDestroy(): void {

    if (this.subGetAllEmployeesForSelect) {
      this.subGetAllEmployeesForSelect.unsubscribe();
    }

    if (this.subSavePqrsInteraction) {
      this.subSavePqrsInteraction.unsubscribe();
    }
  }

  private getAllEmployeesForSelect(): void {
    this.subGetAllEmployeesForSelect = this.personaService.getPersonByTypeRelation([4], environment.socioPacciolo)
      .subscribe((resp: Respuesta) => {
        this.listaEmpleados = resp.data;
      });
  }
}
