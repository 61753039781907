import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SharedService } from './_services/shared.service';
import { SpashComponent } from './spash/spash.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app-spash',
    pathMatch: 'full'
  },
  {
    path: 'app-spash',
    component: SpashComponent,
    data: { title: 'slpash' }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Inicio de Sesión' }
  },
  {
    path: 'Mstr',
    loadChildren: () => import('./Modulos/Master/master.module').then(m => m.MasterModule)
  },
  {
    path: 'politica-datos-personales',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule)
  },
  {
    path: 'registro-cliente',
    loadChildren: () => import('./registroCliente/modules/registro-cliente/registro-cliente.module').then(m => m.RegistroClienteModule)
  },
  {
    path: 'registro-redprocesal',
    loadChildren: () => import('./registro-redprocesal/modules/registro-redprocesal/registro-redprocesal.module')
      .then(m => m.RegistroRedprocesalModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./registro-redprocesal/modules/payment-movii/payment-movii.module').then(m => m.PaymentMoviiModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  declarations: [],
  providers: []
})
export class AppRoutingModule {

  constructor(titleService: Title, router: Router, public ss: SharedService) {

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(router.routerState, router.routerState.root).join('-');
        this.getIcon(router.routerState, router.routerState.root);
        this.ss.titulo = title.replace(',', '');
        titleService.setTitle(`Pasiolo 🌐 ${title}`);
      }
    });
  }

  getTitle(state: any, parent: any): any {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(this.getTitle(state, state.firstChild(parent)));
    }

    return data;
  }

  getIcon(state: any, parent: any): string {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.icono) {
      this.ss.icono = parent.snapshot.data.icono;
      return parent.snapshot.data.icono;
    }

    if (state && parent) {
      data.push(this.getIcon(state, state.firstChild(parent)));
    }

    return '';
  }
}
