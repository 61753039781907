import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { HttpClientService } from '@app/_services/http_client_service/http-client.service';

import { ConsToken } from '@app/_services/constToken';
import { Respuesta } from '@app/_interfaces/respuesta';
import { ResponsabilidadesCalidadesAtributosType } from '@app/persona/types/responsabilidadesCalidadesAtributos';
import { ObligacionesAduanerosType } from '@app/persona/types/obligacionesAduaneros.type';

@Injectable({
  providedIn: 'root'
})
export class PersonaService {

  // TODO: solución temporal cambiar a la implementación de akita
  // tslint:disable-next-line: rx-subject-restrictions
  responsabilidades$ = new Subject<ResponsabilidadesCalidadesAtributosType[]>();
  // tslint:disable-next-line: rx-subject-restrictions
  obligacionAduaneros$ = new Subject<ObligacionesAduanerosType[]>();

  constructor(
    private http: HttpClientService
  ) { }

  createPersona(person: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePersonas + '/personas', person);
  }

  editarPersona(data: any): Observable<Respuesta> {
    return this.http.put(ConsToken.urlMicroServicePersonas + '/personas/', data);
  }

  listar(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getTerceros/${socioPacciolo}`);
  }

  getInfoPersona(socioPasiolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getInfoPersona/${socioPasiolo}`)
  }
  
  getPersona(tercero: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getPersona/${tercero}/${socioPacciolo}`);
  }

  validar_usuario(usuario: any): Observable<Respuesta> {
    return this.http.get(ConsToken.urlNode + '/personal/validar_usuario/' + usuario);
  }

  get_listar_terceros(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlNode + '/personal/listar/terceros/');
  }

  listarEmpleado(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getEmpleados/${socioPacciolo}`);
  }

  listar_persona(): Observable<Respuesta> {
    return this.http.get(ConsToken.url + '/listar_cliente');
  }

  listar_persona_crm(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlNode + '/personal/listar/clientes/');
  }

  listar_clientes_de_empresa(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlNode + '/contrato/listar_empresas_de_cliente');
  }

  listar_Gerentes(id: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlNode + '/contrato/listarGerentes/' + id);
  }

  subir_personas(personas: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlNode + '/personal/subir_personas/', personas);
  }

  eliminarCorreo(data: any): Observable<Respuesta> {
    return this.http.put(ConsToken.urlNode + '/personal/eliminarCorreo/', data);
  }

  eliminarTelefono(data: any): Observable<Respuesta> {
    return this.http.put(ConsToken.urlNode + '/personal/eliminarTelefono/', data);
  }

  eliminarDireccion(data: any): Observable<Respuesta> {
    return this.http.put(ConsToken.urlNode + '/personal/eliminarDireccion/', data);
  }

  subirTercero(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlNode + '/personal/subirTerceros/', data);
  }

  buscarCargosEstados(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlNode + '/personal/buscarCargosEstados/', data);
  }

  /**
   * Función que busca las personas por el tipo de relacion y socio pacciolo
   * @param relaciones Contiene un array de IDs de un tipo de relacion entre personas
   * @param socioPacciolo Contiene el ID de un socio pacciolo
   */
  getPersonByTypeRelation(relaciones: number[], socioPacciolo: number): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePersonas + `/personas/getPersonByTypeRelation/${socioPacciolo}`, relaciones);
  }

  getIdentificationByTypeRelation(relacion: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getIdentificationByTypeRelation/${relacion}/${socioPacciolo}`);
  }

  getTelsByTypeRelation(relacion: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getTelsByTypeRelation/${relacion}/${socioPacciolo}`);
  }

  getEmailByTypeRelation(relacion: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getEmailByTypeRelation/${relacion}/${socioPacciolo}`);
  }

  getEmailByPerson(person: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getEmailByPerson/${person}/${socioPacciolo}`);
  }

  getTercerosWithRelations(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getTercerosWithRelations/${socioPacciolo}`);
  }

  getAllSucursales(socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getSucursalesBySoccioPacciolo/` + socioPacciolo);
  }

  getTiposDeTerceros(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getTiposDeTerceros`);
  }

  getEmpleados(id: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + '/personas/getAllEmployeesForSelect/' + id);
  }

  getInfoContactOrBusinessManager(contactoId: number, duenoContRepre: number, socioPacciolo: number, tipoRelacionId: number)
    : Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getInfoContactOrBusinessManager/${contactoId}/${duenoContRepre}/${socioPacciolo}/${tipoRelacionId}`);
  }

  getInfoBusinessManager(contactoId: number, socioPacciolo: number, duenoContRepre: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + `/personas/getInfoBusinessManager/${contactoId}/${socioPacciolo}/${duenoContRepre}`);
  }

  getInfoSocioPasioloPoliticasYTerminos(personaId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas +
      `/personas/getInfoSocioPasioloPoliticasYTerminos/${personaId}/${socioPacciolo}`);
  }

  getInfopersonaVentas(personaId: number, socioPacciolo: number): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas +
      `/personas/getInfopersonaVentas/${personaId}/${socioPacciolo}`);
  }

  deleteResponsabilidadCalidadAtributos(id: number): Observable<Respuesta> {
    return this.http.delete(ConsToken.urlMicroServicePersonas + `/personas/deleteResponsabilidadCalidadAtributos/${id}`);
  }

  deleteObligacionesAduaneros(id: number): Observable<Respuesta> {
    return this.http.delete(ConsToken.urlMicroServicePersonas + `/personas/deleteObligacionesAduaneros/${id}`);
  }

  deleteActividadEconomica(id: number): Observable<Respuesta> {
    return this.http.delete(ConsToken.urlMicroServicePersonas + `/personas/deleteActividadEconomica/${id}`);
  }

  getCodeCiuusList(): Observable<Respuesta> {
    return this.http.get(ConsToken.urlMicroServicePersonas + '/common/getCodeCiuusList');
  }

  /**
   * @description
   * Función que busca los empleados por los cargos especificados en el
   * parametro positions
   * @param data Contiene un objeto con los atributos:
   * socioPacciolo - Contiene el ID de un socio pasiolo
   * positions - Contiene un array con el ID de los cargos
   */
  getEmployeesByPosition(data: any): Observable<Respuesta> {
    return this.http.post(ConsToken.urlMicroServicePersonas + '/personas/getEmployeesByPosition', data);
  }
}
