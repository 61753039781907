import { Component, OnInit } from '@angular/core';
import { DireccionType } from '@app/persona/types/direccion.type';
import { DireccionService } from '@app/persona/services/direccion/direccion.service';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { Subject } from 'rxjs/Subject';
import { Respuesta } from '@app/_interfaces/respuesta';
import { FacturaType } from '@app/facturacion/types/factura.types';
import { Swal } from '@app/_services/swal';
import { SaldosInicialesService } from '@app/caracteristicasPacciolo/services/saldosIniciales/saldos-iniciales.service';

@Component({
  selector: 'app-layout-saldos-iniciales',
  templateUrl: './layout-saldos-iniciales.component.html',
  styleUrls: ['./layout-saldos-iniciales.component.scss']
})
export class LayoutSaldosInicialesComponent implements OnInit {

  addressList: DireccionType[] = [];
  initialBalance: FacturaType = {};
  

  socioPacciolo = environment.socioPacciolo;
  resetForm = false;

  


  private stopSubscription$ = new Subject<void>();

  constructor(
    private direccionService: DireccionService,
    private saldosInicialesService: SaldosInicialesService
  ) { }

  getDataForm(invoice: FacturaType): void {
    this.initialBalance = invoice;

  }

  /**
   * @description
   * Funcion que guarda el saldo inicial de un cliente como una factura
   */

   save(): void {
    if (this.initialBalance.deudaActualCliente < 1) {
      Swal.alerta('El valor de la deuda debe ser mayor a cero.');
      return;
    }

    const deuda: number = this.initialBalance.deudaActualCliente;

    const saveInitialBalance: FacturaType = {
      socioPacciolo: this.socioPacciolo,
      tercero: 2,
      contratoId: 1,
      direccionId: this.initialBalance.direccionId,
      fechaExpedicion: this.initialBalance.fechaExpedicion,
      saldoAnterior: 0,
      esNula: 'No',
      descuentos: 0,
      cargo: 0,
      periodo: this.initialBalance.descripcion,
      valorParcial: 0,
      valorEstaFactura: deuda,
      tipoTercero: '1',
      deudaActualCliente: deuda,
      pagosRecibidos: 0,
      descripcion: this.initialBalance.descripcion
    };

    this.saldosInicialesService.saveInitBalance(saveInitialBalance)
      .pipe(takeUntil(this.stopSubscription$))
      .subscribe((resp: Respuesta) => {
        if (resp.code === 201) {
          this.initialBalance = {};
          Swal.correcto(resp.mensaje);
        } else if (resp.code === 406) {
          Swal.alerta(resp.mensaje);
        } else {
          Swal.error(resp.mensaje);
        }
      });
  }

  ngOnInit(): void {
    this.getAddressTypeOneTwoForSelect();
  }

  /**
   * @description
   * Función que obtiene las direcciones de tipo 1 y 2
   */
  private getAddressTypeOneTwoForSelect(): void {
    this.direccionService.getAddressTypeOneTwoForSelect(this.socioPacciolo)
      .pipe(takeUntil(this.stopSubscription$))
      .subscribe((resp: Respuesta) => {
        if (resp.code === 200) {
          this.addressList = resp.data;
        }
      });
  }

}
