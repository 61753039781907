import { Component, OnInit, Input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ResolucionFacturaType } from '@app/facturacion/types/resolucionFactura.type';
import { environment } from 'environments/environment';
import { ResolucionFacturacionService } from '@app/facturacion/services/resolucion-factura/resolucion-facturacion.service';
import { toHexString } from 'pdf-lib';
import { Swal } from '@app/_services/swal';

import { takeUntil } from 'rxjs/operators/takeUntil';
import { Subject } from 'rxjs/Subject';
import { Respuesta } from '@app/_interfaces/respuesta';

@Component({
  selector: 'app-resolucion-table',
  templateUrl: './resolucion-table.component.html',
  styleUrls: ['./resolucion-table.component.scss']
})
export class ResolucionTableComponent implements OnInit {
  
  resolution:any = [];
  invoiceList = [];
  dataresolution: ResolucionFacturaType = {};
  resetForm = false;

  socioPacciolo = environment.socioPacciolo;

  @Input() displayedColumns: string[];
  
  dataSource = new MatTableDataSource<ResolucionFacturaType>();
  pageSizeOptions = environment.pageSizeOptions;
  private stopSubscription$ = new Subject<void>();
  
  constructor(
    private resolucionFacturacion: ResolucionFacturacionService
  ) { }


  

  ngOnInit(): void {
    this.getResolution();
  }
  
  public getResolution():void {
    this.resolucionFacturacion.getResolution().subscribe(
      res => {
        this.dataSource.data = res.data
        
      },
      err => console.error(err)
    )
    this.resolution
  }

}
function err(err: any): any {
  throw new Error('Function not implemented.');
}

