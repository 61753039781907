import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import exportFromJSON from 'export-from-json';

import * as moment from 'moment';
import * as XLSX from 'xlsx';

import { Swal } from '@app/_services/swal';
import { EmailType } from '@app/persona/types/email.type';
import { FacturaType } from '@app/facturacion/types/factura.types';
import { TelefonoType } from '@app/persona/types/telefono.type';
import { PagosHasFacturasType } from '@app/pagos/types/pagosHasFacturas.type';

import { NotaFacturaType } from '@app/facturacion/types/notaFactura.type';
import { ImpuestoProductoDetalleFactura } from '@app/facturacion/types/impuestoProductoDetalleFactura.type';
import { UploadFileType } from '@app/_interfaces/uploadFile.type';
import { DescuentosType } from '@app/pagos/types/descuentos.type';

import { Session } from '../_class/session';
import { PdfService } from './pdf/pdf.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  title = '';
  titulo = null;
  icono = null;

  usuario = Session.getItem('usuario');
  paginador = [10, 25, 50, 75, 100];

  icon = '';
  idPaises = 0;

  alertparam = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
    animate: 'sacale'
  };

  noteRead = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: true,
    animate: 'sacale',
    position: ['bottom', 'center']
  };

  quickMessage = {
    timeOut: 1000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: true,
    animate: 'sacale',
    position: ['bottom', 'center']
  };

  dataConverExcelToJson = [];

  constructor(
    private _location: Location,
    private pdfService: PdfService
  ) { }

  filterAutoComplete(val: string, object: any, propiedad: string): void {
    if (val) {
      val = val.toLocaleLowerCase();
      return object.filter(state =>
        state[propiedad].toLocaleLowerCase().startsWith(val)
      );
    }
    return object;
  }

  formatDate(fecha: any = new Date(), formato: string = 'YYYY-MM-DD'): string {
    if (fecha) {
      return moment(fecha).format(formato);
    }

    return null;
  }

  truncateSentence(sentence: string, len: number): string {

    if (sentence && sentence.length > len) {
      return `${sentence.substr(0, len)}...`;
    }

    return sentence;
  }

  cedulaMask(ced: string): string {
    let aux = 1;
    let ret = '';

    if (ced) {

      for (let i = ced.length + 1; i >= 0; i--) {

        if (ced.charAt(i) + '' !== '') {
          if (aux === 3 && i > 0) {
            ret = '.' + ced.charAt(i) + ret;
            aux = 1;
          } else {
            ret = ced.charAt(i) + ret;
            aux++;
          }
        }
      }

      ced = ret;
      return ced;
    }

    return '';
  }

  formatCadena(valor: string): string {
    let cadena = '';

    // tslint:disable-next-line:radix
    const numero = parseInt(valor) + 1;
    const result = JSON.stringify(numero);
    const ceros = valor.split('0');
    for (const cero of ceros) {
      // tslint:disable-next-line:quotemark
      if (cero === "") {
        cadena += '0';
      }
    }

    cadena = cadena + result;
    return cadena;

  }

  /**
   * Función que convierte la letra inicial de cada palabra en mayuscula
   * @param str Contienve un string
   */
  capitalize(str: any): string {

    if (str) {
      str = str.split(' ');

      for (let i = 0, x = str.length; i < x; i++) {
        if (str[i] !== 'de' && str[i] !== 'del' && str[i] !== 'la' && str[i] !== 'las' && str[i] !== 'a' && str[i] !== 'e'
          && str[i] !== 'i' && str[i] !== 'o'
          && str[i] !== 'u' && str[i] !== 'y' && str[i] !== 'los' && str[i] !== 'lo') {
          str[i] = str[i][0].toUpperCase() + str[i].substr(1).toLowerCase();
        }
      }

      return str.join(' ');
    }

    return '';
  }

  back(): void {
    this._location.back();
  }

  minDate(): Date {
    return new Date();
  }

  /**
   * @description
   * Función que convierte un excel en un array de objectos
   * @param event Obligatorio: Recibe el evento del input
   * @param header Obligatorio: Recibe un array de string el cual contiene el nombres de los atributos de los objectos del array
   * de respuesta
   * @param callback Obligatorio: Recibe una función con un parametro el cual contiene la data del archivo excel
   * @returns Retorna un array de objectos.
   * Nota: sino se coloca el parametro header como un array vacio se tomará la primera fila del excel como el nombre de los atributos
   * @example convertExcelToJson(event, [], (resp) => {console.log(resp)})
   */
  convertExcelToJson(event: any, header: string[], callback: any = (_param: Array<any>) => { }): Array<any> {

    const file = event.target.files[0];
    const tipo = file.name.split('.');
    const reader = new FileReader();

    if (tipo[1] !== 'xlsx') {
      Swal.alerta('Por elija un archivo excel..!');
      return;
    }

    let workBook = null;
    let jsonData = [];

    reader.onload = (e: any) => {

      const data = new Uint8Array(e.target.result);
      workBook = XLSX.read(data, { type: 'array' });

      const name = workBook.SheetNames[0];
      const sheet = workBook.Sheets[name];

      if (header.length > 0) {
        jsonData = XLSX.utils.sheet_to_json(sheet, { header, raw: true });
        jsonData.shift();
      } else {
        jsonData = XLSX.utils.sheet_to_json(sheet, { raw: true });
      }

      callback(jsonData);
    };

    reader.readAsArrayBuffer(file);
  }

  convertArrayPhonesToString(telefonos: TelefonoType[], tipo: string): string {
    return telefonos.filter((item: TelefonoType) => item.tipo === tipo)
      .reduce((acc: string, cur: TelefonoType) => acc ? `${acc} , ${cur.numero.toString()}` : cur.numero.toString(), '');
  }

  convertArrayEmailsToString(emails: EmailType[]): string {
    return emails.reduce((acc: string, cur: EmailType) => acc ? `${acc}, ${cur.correo}` : cur.correo, '');
  }

  /**
   * @description
   * Función que mapea las facturas para darles la forma para ser imprimidas
   * @param invoices Contiene un listado de facturas
   */
  mappingInvoice(invoices: FacturaType[]): any {
    try {
      return invoices.map((item: FacturaType) => {
        return {
          id: item.id,
          consecutivo: item.consecutivo,
          prefijo: item.prefijo,
          folio: item.folio,
          fechaExpedicion: item.fechaExpedicion,
          cliente: item.direccion.persona.nombre,
          identificacion: item.direccion.persona.numeroIdentificacion,
          cargo: item.direccion.persona.relacionEntrePersona[0].cargo ?
            item.direccion.persona.relacionEntrePersona[0].cargo.nombre : null,
          ciudad: item.direccion.ciudad.nombre,
          direccion: item.direccion.barrio ? item.direccion.barrio : '',
          periodo: item.periodo,
          deudaActualCliente: item.deudaActualCliente,
          valorEstaFactura: item.valorEstaFactura,
          saldoAnterior: item.saldoAnterior,
          pagosRecibidos: item.pagosRecibidos,
          totalAbonos: item.pagosHasFacturas ?
            item.pagosHasFacturas.reduce((acc: number, cur: PagosHasFacturasType) => acc + cur.valorPago, 0) : 0,
          detalleFactura: item.detalleFactura ? item.detalleFactura : [],
          totalNotas: parseFloat((item.notas.reduce((acc: number, note: NotaFacturaType) =>
            note.tipo === 'NC' ? acc - note.valor : acc + note.valor, 0)).toFixed(2)),
          totalDescuentos: item._descuentos.reduce((acc: number, discount: DescuentosType) => acc + discount.valor, 0)
        };
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * @description
   * Función que enmascara una cedula
   * @param ced Contiene una cedula
   */
  maskCedula(ced: string): string {
    if (ced) {
      let aux = 1;
      let ret = '';

      for (let i = ced.length + 1; i >= 0; i--) {
        if (ced.charAt(i) + '' !== '') {
          if (aux === 3 && i > 0) {
            ret = '.' + ced.charAt(i) + ret;
            aux = 1;
          } else {
            ret = ced.charAt(i) + ret;
            aux++;
          }
        }
      }

      ced = ret;
      return ced;
    }

    return '';
  }

  /**
   * @description
   * Función que convierte un string en Minuscula
   * @param str Contiene un string
   */
  toLowerCase(str: String): string {
    if (str) { str.toLowerCase(); }
    return '';
  }

  /**
   * @description
   * Función que convierte un string en Mayuscula
   * @param str Contiene un string
   */
  toUpperCase(str: String): string {
    if (str) { str.toUpperCase(); }
    return '';
  }

  /**
   * @description
   * Función que calcula el dígito de verificación
   * @param numeroIdentificacion Contiene una identificación
   */
  calDV(numeroIdentificacion: string): string {

    const primos: number[] = new Array(71, 67, 59, 53, 47, 43, 41, 37, 29, 23, 19, 17, 13, 7, 3);
    let acum = 0;
    let rs = 0;
    let pinc = primos.length - String(numeroIdentificacion).length;

    const num = numeroIdentificacion + '';

    if (numeroIdentificacion !== '' && String(numeroIdentificacion).length <= 15) {
      for (let i = 0; i < num.length; i++) {
        const aux = parseInt(num.charAt(i), 0);
        acum += aux * primos[pinc];
        pinc++;
      }

      rs = acum % 11;

      if (rs === 0 || rs === 1) {
        return rs + '';
      }

      return (11 - rs + '');
    }
  }

  /**
   * @Función
   * Función que calcula el porcetaje de X en total
   * @param x Contiene un valor númerico
   * @param total Contiene un valor númerico
   * @example x = 20; total = 50 resultado = (20÷50)×100 = 40%
   * @returns Esta función retorna un número expresado en porcentaje
   */
  calPercentageXinY(x: number, total: number): number {
    if (x && total) { return parseFloat((x / total * 100).toString()); }
    return 0;
  }

  /**
   * @description
   * Función que averigua qué cantidad real representa un porcentaje X de un total
   * @param porcentaje Contiene un valor númerico expresado en porcentaje ej: 20%
   * @param total Contiene un valor númerico
   * @example porcentaje = 20; total = 50 resultado = (20*50)/100 = 10$
   * @returns Esta función retorna un numero expresado en valor real
   */
  calPercentage(porcentaje: number, total: number): number {
    if (porcentaje && total) { return parseFloat((porcentaje * total / 100).toString()); }
    return 0;
  }

  toFixed(valor: number, decimals: number): string {
    if (valor) { return valor.toFixed(decimals); }
    return '';
  }

  parseFloat(valor: string): number {
    if (valor) { return parseFloat(valor); }
    return 0;
  }

  /**
   * @description
   * Función que calcula el ancho de una imagen en base a la relación aspecto
   * @param logo Contiene un string
   * @param callback Un callback
   */
  calculateAspectRatioImage(height: number, logo: string, callback: Function = (_param: any) => { }): void {

    let width: number;
    const image = new Image();
    image.src = logo;

    image.onload = () => {
      const relacion = image.naturalHeight / image.naturalWidth;
      width = height / relacion;
      callback(width);
    };
  }

  /**
   * @description
   * Función que genera un excel a partir de un array de Objectos
   * @param data Contiene un array de Objetos de tipo any
   * @param fileName Contiene un string que tiene el nombre del archivo
   */
  generateExcel(data: any[], fileName: string): void {
    exportFromJSON({ data, fileName, exportType: 'xls' });
  }

  /**
   * @description
   * Función que calcula el valor del impuesto para cada uno de ellos
   * @param taxes Contiene un array de tipo ImpuestoProductoDetalleFactura
   * @param valorParcial Contiene un number
   * @returns Un array de tipo ImpuestoProductoDetalleFactura
   */
  calTaxesValue(taxes: ImpuestoProductoDetalleFactura[], valorParcial: number): ImpuestoProductoDetalleFactura[] {
    return taxes.map((tax: ImpuestoProductoDetalleFactura) => {
      tax.valor = parseFloat(this.calPercentage(tax.porcentaje, valorParcial).toFixed(2));
      return tax;
    });
  }

  /**
   * @description
   * Función que calcula el total de impuestos aplicados a un producto
   * @param taxes Contiene un array de objetos de tipo ImpuestoProductoDetalleFactura
   * @returns Un number
   */
  getTotalTaxes(taxes: ImpuestoProductoDetalleFactura[]): number {
    const total = taxes.reduce((acc: number, tax: ImpuestoProductoDetalleFactura) => acc + tax.valor, 0);
    return parseFloat(total.toFixed(2));
  }

  /**
   * @description
   * Función que verifica si un numero es entero o no
   * @param num Contiene un number
   * @returns boolean
   */
  IsWholeNumber(num: number): boolean {
    if (num % 1 === 0) { return true; }
    return false;
  }

  /**
   * @description
   * Función que muestra un pdf en otra pestaña del navegador
   * @param fileList Contiene un array de tipo UploadFileType
   * @param fileName 
   * @returns un void
   */
  async showPDFNewTap(fileList: UploadFileType[], fileName: string = 'pdf'): Promise<void> {

    const pdf = await this.pdfService.createOnePdf(fileList, fileName);
    const link = document.createElement('a');
    
    link.href = window.URL.createObjectURL(pdf);
    window.open(link.href, '_blank');
    window.URL.revokeObjectURL(link.href);
  }
}
