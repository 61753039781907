import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, NgForm, Validators } from '@angular/forms';

import { Subscription } from 'rxjs/Subscription';

import { Swal } from '@app/_services/swal';
import { CargosService } from '@app/caracteristicasPacciolo/services/cargos/cargos.service';

import { CargosType } from '@app/persona/types/cargos.type';
import { DireccionType } from '@app/persona/types/direccion.type';
import { Respuesta } from '@app/_interfaces/respuesta';
import { environment } from 'environments/environment';
import { MomentService } from '@app/_services/moment/moment.service'; 

@Component({
  selector: 'app-form-saldos-iniciales',
  templateUrl: './form-saldos-iniciales.component.html',
  styleUrls: ['./form-saldos-iniciales.component.scss']
})
export class FormSaldosInicialesComponent implements OnInit {

  @ViewChild('formDiscount') formDiscount: NgForm;

  @Input() addressList: DireccionType[] = [];
  @Input() resetForm!: boolean;

  @Output() sendDataForm = new EventEmitter<any>();

  formData = this.formBuild.group({
    direccionId: [null],
    deudaActualCliente: [null],
    descripcion: [null],
    fechaExpedicion: [null]
  });

  currentInvoiceBalance = new FormControl(null);

  constructor(
    private formBuild: FormBuilder,
    private momentService: MomentService
  ) { }

  /**
   * @description
   * Función que emite la información del formulario
   */
   onSendData(): void {
    this.formData.patchValue({
      fechaExpedicion: this.momentService.format(this.formData.get('fechaExpedicion').value)
    });
    
    this.sendDataForm.emit(this.formData.value);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changeAddressList(changes);
    this.changeResetForm(changes)
  }

  /**
   * @description
   * Función que observa los cambios de la propiedad addressList
   * @param change Contiene un objeto de tipo SimpleChanges
   */
  private changeAddressList(change: SimpleChanges): void {
    if (change.addressList && !change.addressList.firstChange) {
      this.addressList = change.addressList.currentValue;
    }
  }

  /**
   * @description
   * Función que observa los cambios de la propiedad resetForm
   * @param change Contiene un objeto de tipo SimpleChanges
   */
   private changeResetForm(change: SimpleChanges): void {
    if (change.resetForm && !change.resetForm.firstChange && change.resetForm.currentValue) {
      this.resetForm = change.resetForm.currentValue;
      this.currentInvoiceBalance.setValue(null);
      this.formDiscount.form.reset();
      this.formDiscount.form.markAsPristine();
    }
  }

}
