import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacturaType } from '@app/facturacion/types/factura.types';
import { FormBuilder } from '@angular/forms';
import { Swal } from '@app/_services/swal';
import { Respuesta } from '@app/_interfaces/respuesta';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { FacturacionService } from '@app/facturacion/services/facturacion.service';
import { FacturacionElectronicaService } from '@app/_services/facturacionElectronica/facturacion-electronica.service';

@Component({
  selector: 'app-modal-invoice-bulk',
  templateUrl: './modal-invoice-bulk.component.html',
  styleUrls: ['./modal-invoice-bulk.component.scss']
})
export class ModalInvoiceBulkComponent implements OnInit, OnChanges {

  invoice = [];
  invoiceList: any = this.data
  resultado = []
  cid = []
  periodo

  formData = this.formBuild.group({
    periodo: ['']
  })


  socioPacciolo = environment.socioPacciolo;
  private stopSubscription$ = new Subject<void>();
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuild: FormBuilder,
    private facturacionService: FacturacionService,
    private facturacionElectronicaService: FacturacionElectronicaService
  ) { }

  /**
   * @description
   * Función que genera una factura electrónica en bloque
   * @param invoice Contiene un objeto de tipo FacturaType
   */
   onBlockGenerateElectronicInvoice() { 
     
      const periodo = (this.formData.get('periodo').value.consecutivo)
    

      this.facturacionElectronicaService.getInvoicesFromBulkBilling(periodo, this.socioPacciolo)
      .pipe(takeUntil(this.stopSubscription$))
      .subscribe((resp: Respuesta) => {
        if (resp.code === 201) {
          Swal.correcto(`Facturas con del periodo #${this.invoiceList.periodo} generadas electrónicamente con exito.`);
        } else if (resp.code === 406) {
          Swal.alerta(resp.mensaje);
        } else {
          Swal.error(resp.mensaje);
        }
      });  
  }

  ngOnInit(): void {
    this.periodo = this.data.periodo
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  filterById() {
  }


  


}
