<form [formGroup]="formData">
  <mat-card>
    <mat-card-content>
      <div fxLayout.lt-md="column wrap" fxLayoutGap="10px">

        <mat-form-field fxFlex appearance="outline" style="max-width: 40%;">
          <mat-label for="periodo"> Seleccione el periodo que desea generar electronicamente</mat-label>
          <mat-select id="periodo" name="periodo" formControlName="periodo" (change)="onBlockGenerateElectronicInvoice()" >
            
            <mat-option>
              <ngx-mat-select-search noEntriesFoundLabel="No hay periodos" placeholderLabel="Buscar..." #search
              name="periodoInvoice">
              </ngx-mat-select-search>
            </mat-option>

            <mat-option [value]="periodo" >
                 {{periodo.periodo}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div>
          <mat-form-actions style="margin-top: 50%;">
            <button button mat-raised-button color="primary" (click)="onBlockGenerateElectronicInvoice()"
              matTooltip="Generar electronicamente el periodo seleccionado" matTooltipPosition="above">Generar</button>
          </mat-form-actions>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</form>
