import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ResolucionFacturaType } from '@app/facturacion/types/resolucionFactura.type';
import { Swal } from '@app/_services/swal';
import { environment } from 'environments/environment';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { Respuesta } from '@app/_interfaces/respuesta';
import { MomentService } from '@app/_services/moment/moment.service';
import { ResolucionFacturacionService } from '@app/facturacion/services/resolucion-factura/resolucion-facturacion.service';


@Component({
  selector: 'app-resolucion-facturacion',
  templateUrl: './resolucion-facturacion.component.html',
  styleUrls: ['./resolucion-facturacion.component.scss']
})
export class ResolucionFacturacionComponent implements OnInit {
  displayedColumns: string[] = ['personaId', 'numeroAutorizacion', 'prefijo', 'fechaInicio', 'fechaFin', 'tipoResolucion', 'numeroInicial', 'numeroFinal'];
  
  @Output() sendResolutionForm = new EventEmitter<any>();
  @ViewChild('formInvoice') formInvoice: NgForm;


  
  private stopSubscription$ = new Subject<void>();

  resolution: ResolucionFacturaType = {
    socioPacciolo : environment.socioPacciolo,
    personaId: null,
    prefijo: null,
    nombre: null,
    numeroAutorizacion: null,
    numeroInicial: null,
    numeroFinal: null,
    fechaInicio: null,
    fechaFin: null,
    estado: null
  };
  
  constructor(
    private formBuilder: FormBuilder,
    private resolucionFacturacion: ResolucionFacturacionService,
    private momentService: MomentService
  ) { }


  onSaveResolution():void {

    if(this.resolution.numeroInicial > this.resolution.numeroFinal) {
      Swal.alerta('El numero inicial debe ser menor al numero final');
      return;
    }

    if(this.resolution.fechaInicio > this.resolution.fechaFin){
      Swal.alerta('La fecha inicio debe ser menor que la fecha fin');
      return;
    }
    this.resolution.nombre = this.resolution.prefijo;
    this.resolution.fechaInicio = this.momentService.format(this.resolution.fechaInicio);
    this.resolution.fechaFin = this.momentService.format(this.resolution.fechaFin);
    
    console.log(this.resolution,'DATA')
    this.resolucionFacturacion.saveResolution(this.resolution)
    .pipe(takeUntil(this.stopSubscription$))
    .subscribe((resp: Respuesta) => {
      if (resp.code === 201) {
        this.formInvoice.reset();
        this.formInvoice.form.markAsPristine();
        Swal.correcto(resp.mensaje);
      }else if (resp.code === 406) {
        Swal.alerta(resp.mensaje);
      } else {
        Swal.error(resp.mensaje);
      }
    })
  }

  ngOnInit(): void {
  }

}
