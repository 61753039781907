<table fxFlex mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  <div mat-dialog-content style="overflow-x: hidden;">
    <div fxFlex="100%">

      <div style="margin-top: 1%;" fxLayout="row wrap" fxLayoutGap="20px">
        <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="1%">

          <ng-container fxFlex matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef> cliente </th>
            <td mat-cell *matCellDef="let element">{{element.nombre}}</td>
          </ng-container>

          <ng-container fxFlex matColumnDef="month1">
            <th mat-header-cell *matHeaderCellDef> Mes1 </th>
            <td mat-cell *matCellDef="let element"> {{element.dato}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="month2" hidden>
            <th mat-header-cell *matHeaderCellDef hidden> Mes2 </th>
            <td mat-cell *matCellDef="let element" hidden> {{element.dato}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="month3">
            <th mat-header-cell *matHeaderCellDef> Mes3 </th>
            <td mat-cell *matCellDef="let element"> {{element.dato}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="empty">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element"> {{element.dato}} </td>
          </ng-container>

          <ng-container fxFlex matColumnDef="mediana">
            <th mat-header-cell *matHeaderCellDef> Mediana </th>
            <td mat-cell *matCellDef="let element"> {{element.dato}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </div>
      </div>
    </div>
  </div>
</table>